import Vue from "vue";
import VueRouter, { Route, NavigationGuard } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Users from "../views/Users.vue";
import User from "../views/User.vue";
import HoldingCompany from "../views/HoldingCompany.vue";
import HoldingCompanies from "../views/HoldingCompanies.vue";
import DestinationAbattoir from "../views/DestinationAbattoir.vue";
import DestinationAbattoirs from "../views/DestinationAbattoirs.vue";
import Feedlot from "../views/Feedlot.vue";
import ProcessingStation from "../views/ProcessingStation.vue";
import GantryScanner from "../views/GantryScanner.vue";
import HandHeldScanner from "../views/HandHeldScanner.vue";
import Abattoir from "../views/Abattoir.vue";
import Role from "../views/Role.vue";
import BigQueryDatasets from "../views/BigQueryDatasets.vue";
import BigQueryTable from "../views/BigQueryTable.vue";
import FeedlotDestination from "../views/FeedlotDestination.vue";
import Servers from "../views/Servers.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { requiresRegistration: true }
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    meta: { requiresRegistration: true }
  },
  {
    path: "/user",
    name: "user",
    component: User,
    meta: { requiresRegistration: true },
    props: true
  },
  {
    path: "/holding-company",
    name: "holding-company",
    component: HoldingCompany,
    meta: { requiresRegistration: true },
    props: true
  },
  {
    path: "/servers",
    name: "servers",
    component: Servers,
    meta: { requiresRegistration: true },
    props: true
  },
  {
    path: "/holding-companies",
    name: "holding-companies",
    component: HoldingCompanies,
    meta: { requiresRegistration: true }
  },
  {
    path: "/destination-abattoir",
    name: "destination-abattoir",
    component: DestinationAbattoir,
    meta: { requiresRegistration: true },
    props: true
  },
  {
    path: "/destination-abattoirs",
    name: "destination-abattoirs",
    component: DestinationAbattoirs,
    meta: { requiresRegistration: true }
  },
  {
    path: "/feedlot",
    name: "feedlot",
    component: Feedlot,
    meta: { requiresRegistration: true }
  },
  {
    path: "/processing-station",
    name: "processing-station",
    component: ProcessingStation,
    meta: { requiresRegistration: true }
  },
  {
    path: "/gantry-scanner",
    name: "gantry-scanner",
    component: GantryScanner,
    meta: { requiresRegistration: true }
  },
  {
    path: "/hand-held-scanner",
    name: "hand-held-scanner",
    component: HandHeldScanner,
    meta: { requiresRegistration: true }
  },
  {
    path: "/feedlot-destination",
    name: "feedlot-destination",
    component: FeedlotDestination,
    meta: { requiresRegistration: true }
  },
  {
    path: "/abattoir",
    name: "abattoir",
    component: Abattoir,
    meta: { requiresRegistration: true }
  },
  {
    path: "/role",
    name: "role",
    component: Role,
    meta: { requiresRegistration: true }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: (to: Route, from: Route, next: any) => {
      //If already logged in then go to the home screen
      //console.log(store);
      if (store.getters["user/isUserRegistered"]()) next("/");
      //If not logged in then continue to login page
      else next();
    }
  },
  {
    path: "/bigquery-datasets",
    name: "bigquery-datasets",
    component: BigQueryDatasets,
    meta: { requiresRegistration: true }
  },
  {
    path: "/bigquery-table",
    name: "bigquery-table",
    component: BigQueryTable,
    meta: { requiresRegistration: true },
    props: true
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to: Route, from: Route, next: any) => {
  //Send user to login page if user is not registered (logged in)
  if (to.name === "login") {
    next();
    return;
  }

  let requiresRegistration: boolean = to.matched.some(record => record.meta.requiresRegistration);
  let isUserRegistered: boolean = store.getters["user/isUserRegistered"]();

  if (requiresRegistration && !isUserRegistered) {
    next("/login");
  } else {
    next();
  }
});

export default router;
