var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',[_c('v-col',[_vm._v(" User, uid: "+_vm._s(_vm.user.uid)+" ")])],1),_c('v-row',[_c('v-text-field',{attrs:{"label":"Display Name"},model:{value:(_vm.displayName),callback:function ($$v) {_vm.displayName=$$v},expression:"displayName"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"label":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_vm._v(" Roles ")]),_c('v-col',{attrs:{"cols":"1"}},[_c('dimssa-button',{staticStyle:{"height":"80%"},attrs:{"buttonState":"ready"},on:{"onclick":function($event){return _vm.role()}}},[_vm._v(" "+_vm._s(_vm.selected.length ? "Edit Role" : "New Role")+" ")])],1),_c('v-col',{attrs:{"cols":"1"}},[_c('dimssa-button',{staticStyle:{"height":"80%"},attrs:{"buttonState":"ready"},on:{"onclick":function($event){return _vm.removeRole()}}},[_vm._v(" "+_vm._s("Remove Role")+" ")])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":[
            {
              text: 'Role',
              value: 'role',
            },
            {
              text: 'Location',
              value: 'location',
            },
          ],"items":_vm.userRoles,"search":_vm.search,"single-select":"","show-select":"","item-key":"key"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_vm._v(" JWT Custom Claims ")])],1),_c('v-row',[_c('v-col',[_c('v-card',[_vm._v(" "+_vm._s(JSON.stringify(_vm.customClaims))+" ")])],1)],1),_c('v-row',[_c('v-col',[_c('dimssa-button',{staticClass:"button",attrs:{"buttonState":_vm.getCustomClaimsButtonState},on:{"onclick":function($event){return _vm.getCustomClaims()}}},[_vm._v("Get")])],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{model:{value:(_vm.customClaimsString),callback:function ($$v) {_vm.customClaimsString=$$v},expression:"customClaimsString"}})],1)],1),_c('v-row',[_c('v-col',[_c('dimssa-button',{staticClass:"button",attrs:{"buttonState":_vm.setCustomClaimsButtonState},on:{"onclick":function($event){return _vm.setCustomClaims()}}},[_vm._v("Set")])],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('dimssa-button',{staticClass:"button",attrs:{"buttonState":"ready"},on:{"onclick":function($event){return _vm.save()}}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }