
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import store, { mapFields, mapMultiRowFields } from "@/store";
import lodash from "lodash";
import * as helpers from "@/helpers";
import { createDatasetTable, updateHoldingCompany } from "@/helpers/graphql";
import { getGcp } from "@gigalot/utils";
import { mqttDeviceId, mqttDeviceRegistryId, mqttPrivateKey, mqttPushCertificate, mqttRegisterDevice } from "@/helpers/mqtt";
import { downloadObjectAsJson, downloadObjectAsText } from "@/helpers/download";
import { camelCase, getProjectId } from "@/helpers";
import JSZip from "jszip";
import { MqttDeviceStatus } from "@/components/MqttDeviceStatus.vue";

@Component({
  components: {
    DimssaButton,
    MqttDeviceStatus,
  },
  computed: {
    ...mapFields(["abattoir.name", "abattoir.gcp", "abattoir.vat"]),
  },
})
export default class Abattoir extends Vue {
  serviceAccountName: string = "";

  pubsubGetTopicsButtonState: ButtonState = "ready";
  pubsubCreateTopicsButtonState: ButtonState = "ready";

  createBigQueryTablesButtonState: ButtonState = "ready";

  createServiceAccountButtonState: ButtonState = "ready";

  saveButtonState: ButtonState = "ready";

  mqttDevice = {};

  created() {
    this.mqttDevice = {
      id: this.mqttDeviceId(),
      registry: "Abattoirs",
      state: undefined,
    };
  }

  mqttDeviceId() {
    const what: string = "abattoir";
    const abattoir: Models.Abattoir = this.$store.getters["getField"]("abattoir") as Models.Abattoir;
    const holdingCompany: Models.HoldingCompany = this.$store.getters["getField"]("holdingCompany") as Models.HoldingCompany;
    const abattoirs: Models.Abattoir[] = holdingCompany.abattoirs;
    const index = abattoirs.findIndex((a) => a.guid === abattoir.guid);
    let num = index + 1;
    if (index < 0) {
      //user might be busy with a new abattoir that has not been saved yet.
      num = abattoirs.length + 1;
    }
    return mqttDeviceId(what, this.getGcp(), this.getGuid(), num);
  }

  async createDatasetTable(datasetId: string) {
    try {
      this.createBigQueryTablesButtonState = "busy";
      await createDatasetTable(datasetId, "abattoir");
      this.createBigQueryTablesButtonState = "success";
    } catch (err) {
      console.error(err);
      this.createBigQueryTablesButtonState = "error";
    }
  }

  mounted() {
    this.$store.dispatch("user/addFirebaseCallback", this.loadDefaults);
  }

  loadDefaults() {
    this.serviceAccountName = `${this.getGcp().slice(0, 29)}`;
    console.log(`service account name: ${this.serviceAccountName}`);
  }

  async save() {
    try {
      this.saveButtonState = "busy";
      const holdingCompany: Models.HoldingCompany = this.$store.getters["getField"]("holdingCompany") as Models.HoldingCompany;
      const abattoir: Models.Abattoir = this.$store.getters["getField"]("abattoir") as Models.Abattoir;
      helpers.addToListGuids(holdingCompany.abattoirs, abattoir);
      await updateHoldingCompany(holdingCompany);
      this.saveButtonState = "success";
    } catch (err) {
      console.error(`Error saving Abattoir`);
      console.error(err);
      this.saveButtonState = "error";
    }
  }

  getGcp() {
    const holdingCompany = this.$store.getters["getField"]("holdingCompany");
    const abattoir = this.$store.getters["getField"]("abattoir");
    return getGcp(abattoir, holdingCompany);
  }

  getGuid() {
    return this.$store.getters["getField"]("abattoir").guid;
  }

  get datasetId() {
    const prefix = this.getGcp();
    let guid = this.$store.getters["getField"]("abattoir").guid;
    guid = guid.split("-").join(""); //remove all dashes
    const datasetId = `${prefix}_${guid}`;
    console.log(datasetId);
    return datasetId;
  }

  async getPubSubTopics() {
    try {
      this.pubsubGetTopicsButtonState = "busy";
      let gql = `query pubSubTopics {
        pubSubTopics(gcp: "${this.getGcp()}", locationGuid: "${this.$store.getters["getField"]("abattoir").guid}")
      }`;

      let json = await this.$store.dispatch("graphQl", { gql });

      console.log(JSON.stringify(json));
      this.pubsubGetTopicsButtonState = "success";
    } catch (err) {
      console.log("error: " + err);
      this.pubsubGetTopicsButtonState = "error";
      throw err;
    }
  }

  async createPubSubTopics() {
    try {
      this.pubsubCreateTopicsButtonState = "busy";
      let gql = `mutation createPubSubTopics($gcp: String!, $locationGuid: String!) {
        createPubSubTopics(gcp: $gcp, locationGuid: $locationGuid)
      }`;

      let json = await this.$store.dispatch("graphQl", {
        gql,
        variables: { gcp: this.getGcp(), locationGuid: this.$store.getters["getField"]("abattoir").guid },
      });

      console.log(JSON.stringify(json));
      this.pubsubCreateTopicsButtonState = "success";
    } catch (err) {
      console.log("error: " + err);
      this.pubsubCreateTopicsButtonState = "error";
      this.$store.commit("popup/displayOk", `Error: ${err}`);
    }
  }

  async createServiceAccount(download = true) {
    try {
      this.createServiceAccountButtonState = "busy";
      let gql = `mutation createServiceAccount($gcp: String!, $location: String!) {
        createServiceAccount(gcp: $gcp, location: $location)
      }`;

      let json = await this.$store.dispatch("graphQl", {
        gql,
        variables: { gcp: this.serviceAccountName, location: this.$store.getters["getField"]("abattoir").guid },
      });
      let holdingCompany = this.$store.getters["getField"]("holdingCompany");
      let abattoir = this.$store.getters["getField"]("abattoir");
      let filename = `service-account-${camelCase(abattoir.name)}_${holdingCompany.typename}-${camelCase(holdingCompany.name)}`;

      if (download) downloadObjectAsJson(JSON.parse(json.data.createServiceAccount), filename);
      //console.log(JSON.stringify(json));
      this.createServiceAccountButtonState = "success";
      return json.data.createServiceAccount;
    } catch (err) {
      console.log("error: " + err);
      this.createServiceAccountButtonState = "error";
      throw err;
    }
  }

  configFileProxy(download = true) {
    const json = {
      restfulProxy: {
        description: "HSL rest",
        origin: "*",
        proxyPort: "9000",
        targetAddress: "hsl",
        targetPort: "9004",
      },
      websocketProxies: [
        {
          description: "scale",
          proxyPort: "8002",
          targetAddress: "hsl",
          targetPort: "8006",
        },
        {
          description: "allflex",
          proxyPort: "8003",
          targetAddress: "hsl",
          targetPort: "8007",
        },
      ],
      UploadFolderPath: "C:/Temp/Upload/",
      CounterFolderPath: "C:/Temp/Counter/",
      StorageBucketName: "gigalot-systems-testing-videos",
      VideoFolderPath: "C:/Temp/Videos/",
      GCP: this.getGcp(),
      location: this.getGuid(),
      projectID: getProjectId(),
      processingAppDownloadCacheHours: 24,
      ignoreBodyParser: true,
      mqtt: {
        projectId: getProjectId(),
        registryId: "Abattoirs",
        deviceId: this.mqttDeviceId(),
        region: "europe-west1",
        mqttCertFile: "./Config/mqtt.pem",
        privateKeyFile: "./Config/cert1.key",
        certificateFile: "./Config/cert1.crt.pem",
        mqttBridgeHostname: "mqtt.gigalot.systems",
        mqttBridgePort: 8883,
        updateFile: "./Config/update/update",
        configFile: "./Config/config.json",
        shouldOverwriteConfig: true,
        username: "paul",
        password: "samsung",
      },
    };

    if (download) downloadObjectAsJson(json, "config");
    return json;
  }

  async downloadArchive() {
    var zip = new JSZip();

    zip.file("service-account.json", await this.createServiceAccount(false));
    //zip.file("mqtt.pem", await this.mqttPrivateKey(false));
    zip.file("config.json", JSON.stringify(this.configFileProxy(false)));

    zip.generateAsync({ type: "blob" }).then(function (content) {
      var downloadAnchorNode = document.createElement("a");

      let dataURL = (window.URL || window.webkitURL).createObjectURL(content);
      downloadAnchorNode.setAttribute("href", dataURL);
      downloadAnchorNode.setAttribute("download", `archive.zip`);
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  }
}
