var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[(!_vm.gotTableIds)?_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(_vm.message)+" ")])],1):_vm._e(),(_vm.gotTableIds)?_c('v-row',[_c('v-col',[_c('v-data-table',{key:_vm.updateKey,attrs:{"dense":"","headers":[
          { text: 'Dataset ID', value: 'datasetId' },
          { text: 'Tables', value: `tables.length` },
          { text: 'Exists', value: `exists` },
        ],"items":_vm.datasetTableIds,"single-expand":"","show-expand":"","expanded":_vm.expandedDatasets,"item-key":"datasetId","items-per-page":-1},on:{"update:expanded":function($event){_vm.expandedDatasets=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Datasets")])],1)]},proxy:true},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-data-table',{key:_vm.updateInnerTableKey,attrs:{"dense":"","headers":[
                      { text: 'Table', value: 'name' },
                      { text: 'Actions', value: 'actions', sortable: false },
                    ],"items":item.tables.map((t) => ({ ...t, datasetId: item.datasetId })),"items-per-page":item.tables.length,"hide-default-footer":"","sort-desc":false},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-container',{attrs:{"fluid":"","pa-0":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('dimssa-button',{attrs:{"buttonState":"ready"},on:{"onclick":function($event){return _vm.viewTable(item.datasetId, item.name)}}},[_vm._v(" View ")])],1),_c('v-col',[_c('dimssa-button',{attrs:{"buttonState":_vm.datasetTableExistsButtonStates[item.datasetId][item.name]},on:{"onclick":function($event){return _vm.clickDatasetTableCheck(item.datasetId, item.name)}}},[_vm._v(" Check ")])],1)],1)],1)]}}],null,true)})],1)],1)],1)],1)]}},{key:`item.exists`,fn:function({ item }){return [_c('dimssa-button',{attrs:{"buttonState":_vm.datasetExistsButtonStates[item.datasetId]},on:{"onclick":function($event){return _vm.clickDatasetExists(item.datasetId)}}},[_vm._v("Exists")])]}}],null,true)})],1)],1):_vm._e(),(_vm.gotTableIds)?_c('v-row',[_c('v-col',[_c('dimssa-button',{staticClass:"button",attrs:{"buttonState":_vm.lookForProblemsButtonState},on:{"onclick":function($event){return _vm.lookForProblems()}}},[_vm._v("Look for problems")])],1),_c('v-col',[_c('dimssa-button',{staticClass:"button",attrs:{"buttonState":_vm.fixProblemsButtonState},on:{"onclick":function($event){return _vm.fixProblems()}}},[_vm._v("Fix Problems")])],1)],1):_vm._e(),(_vm.gotTableIds)?_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"dense":"","items":_vm.problemItems,"headers":[
          { text: 'Type', value: 'type' },
          { text: 'Dataset Id', value: 'datasetId' },
          { text: 'Table Id', value: 'tableId' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],"items-per-page":-1},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Problems")])],1)]},proxy:true},{key:`item.actions`,fn:function({ item }){return [_c('v-container',{attrs:{"fluid":"","pa-0":""}},[_c('v-row',{attrs:{"dense":""}},[(item.type !== 'non-existing-dataset')?_c('v-col',[_c('dimssa-button',{attrs:{"buttonState":"ready"},on:{"onclick":function($event){return _vm.viewTable(item.datasetId, item.tableId)}}},[_vm._v(" View ")])],1):_vm._e(),_c('v-col',[(item.type === 'non-existing-dataset')?_c('dimssa-button',{attrs:{"dense":"","buttonState":item.buttonState},on:{"onclick":function($event){return _vm.createDataset(item)}}},[_vm._v(" Create Dataset ")]):(item.type === 'non-existing-table')?_c('dimssa-button',{attrs:{"buttonState":item.buttonState},on:{"onclick":function($event){return _vm.createTable(item)}}},[_vm._v(" Create Table ")]):(item.type === 'outdated-table-schema')?_c('dimssa-button',{attrs:{"buttonState":item.buttonState},on:{"onclick":function($event){return _vm.updateSchema(item)}}},[_vm._v(" Update Schema ")]):_vm._e()],1)],1)],1)]}}],null,true)})],1)],1):_vm._e(),(!_vm.problemItems.length && _vm.lookForProblemsButtonState === 'success')?_c('v-row',[_c('v-col',[_vm._v(" No Problems detected! ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }