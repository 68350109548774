
import { Component, Vue, Watch } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import * as helpers from "@/helpers";
import { updateHoldingCompany } from "@/helpers/graphql";
import { getGcp } from "@gigalot/utils";
import { mapFields } from "@/store";

@Component({
  components: {
    DimssaButton,
  },
  //   computed: {
  //     ...mapFields([
  //       "feedlotDestination.type",
  //       "feedlotDestination.locationGuid",
  //       "feedlotDestination.holdingCompanyGuid",
  //       "feedlotDestination.locationName",
  //       "feedlotDestination.holdingCompanyName",
  //       "feedlotDestination.gcp",
  //     ]),
  //   },
})
export default class FeedlotDestination extends Vue {
  selectedHoldingCompany: Models.HoldingCompany | "" = "";
  selectedType: "feedlot" | "abattoir" | "" = "";
  selectedDestination: Models.Feedlot | Models.Abattoir | "" = "";
  saveButtonState: ButtonState = "ready";

  get feedlot(): Models.Feedlot {
    return this.$store.getters["getField"]("feedlot");
  }

  get feedlotDestination(): Models.FeedlotDestination {
    return this.$store.getters["getField"]("feedlotDestination");
  }

  get holdingCompanies(): Models.HoldingCompany[] {
    return this.$store.getters["getField"]("holdingCompanies");
  }

  get destinations(): Models.Feedlot[] | Models.Abattoir[] {
    let ret = [];
    if (!this.selectedHoldingCompany) return [];
    if (this.selectedType === "feedlot") return this.selectedHoldingCompany.feedlots.filter((f) => f.guid !== this.feedlot.guid);
    return [];
  }

  created() {
    //TODO: look if selectedDestination already has a holding company guid, fill out selectedHoldingCompany if needed
    //TODO: same for selectedType
    //TODO: same for selectedDestination
  }

  async save() {
    try {
      this.saveButtonState = "busy";

      if (!this.selectedHoldingCompany) {
        this.$store.commit("popup/displayOk", `Can not save, holding company must be selected.`);
        this.saveButtonState = "error";
        return;
      }

      if (!this.selectedType) {
        this.$store.commit("popup/displayOk", `Can not save, destination type must be selected.`);
        this.saveButtonState = "error";
        return;
      }

      if (!this.selectedDestination) {
        this.$store.commit("popup/displayOk", `Can not save, destination must be selected.`);
        this.saveButtonState = "error";
        return;
      }

      const holdingCompany = this.$store.getters["getField"]("holdingCompany");
      let feedlot: Models.Feedlot = this.$store.getters["getField"]("feedlot");
      if (!feedlot.destinations) feedlot.destinations = [];

      //Check if this destination has already been selected.
      if (feedlot.destinations.find((d) => this.selectedDestination && d.locationGuid === this.selectedDestination.guid)) {
        this.$store.commit("popup/displayOk", `Can not save, this destination has already been chosen for ${feedlot.name}.`);
        this.saveButtonState = "error";
        return;
      }

      const feedlotDestination = this.feedlotDestination;
      feedlotDestination.type = this.selectedType;
      feedlotDestination.locationGuid = this.selectedDestination.guid;
      feedlotDestination.locationName = this.selectedDestination.name;
      feedlotDestination.holdingCompanyGuid = this.selectedHoldingCompany.guid;
      feedlotDestination.holdingCompanyName = this.selectedHoldingCompany.name;
      feedlotDestination.gcp = getGcp(this.selectedDestination, this.selectedHoldingCompany);

      feedlot.destinations.push(feedlotDestination);
      helpers.addToListGuids(holdingCompany.feedlots, feedlot);
      this.$store.commit("updateField", { path: "feedlot", value: feedlot });
      await updateHoldingCompany(holdingCompany);
      this.saveButtonState = "success";
    } catch (err) {
      console.error(`Error saving Feedlot Destination`);
      console.error(err);
      this.saveButtonState = "error";
    }
  }

  @Watch("selectedHoldingCompany")
  onSelectedHoldingCompanyChanged(val: any, oldVal: any) {
    if (val?.guid !== oldVal?.guid) this.selectedDestination = "";
  }

  @Watch("selectedType")
  onSelectedTypeChanged(val: any, oldVal: any) {
    if (val !== oldVal) this.selectedDestination = "";
  }

  // @Watch("selectedDestination")
  // onSelectedDestinationChanged(val: any, oldVal: any) {

  // }
}
