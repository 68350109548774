import * as Models from "@gigalot/data-models";
import store from "@/store";

export function gqlSchemaFragmentHelper(depth: number): string {
  if (depth <= 0) return "fields { ...schemaFields }";
  return `fields { ...schemaFields ${gqlSchemaFragmentHelper(depth - 1)} }`;
}

export const gqlDatasetTable = `
	query datasetTable($datasetId: String!, $tableId: String!) {
		datasetTable(datasetId: $datasetId, tableId: $tableId) {
			dataset
			tables {
				name
				tableSchema {
					...schema
				}
				latestSchema {
					...schema
				}
			}
		}
	} fragment schema on Schema {
		${gqlSchemaFragmentHelper(10)}
	}	fragment schemaFields on SchemaField {
		name
		type
		mode
	}`;

export async function updateHoldingCompany(holdingCompany: Models.HoldingCompany) {
  store.commit("updateField", { path: "holdingCompany", value: holdingCompany });
  console.log("updateHoldingCompany");
  console.log(JSON.stringify(holdingCompany));
  console.log("======");
  let gql = `mutation updateHoldingCompany($holdingCompany: HoldingCompanyInput!) {
		updateHoldingCompany(holdingCompany: $holdingCompany)
	}
	`;

  let json = await store.dispatch("graphQl", {
    gql,
    variables: {
      holdingCompany: holdingCompany
    }
  });
  console.log(JSON.stringify(json));
}

export async function updateDestinationAbattoir(destinationAbattoir: Models.Abattoir) {
  //store.commit("updateField", { path: "destinationAbattoir", value: destinationAbattoir });
  console.log("updateDestinationAbattoir");
  console.log(JSON.stringify(destinationAbattoir));
  console.log("======");
  let gql = `mutation updateDestinationAbattoir($destinationAbattoir: AbattoirInput!) {
		updateDestinationAbattoir(destinationAbattoir: $destinationAbattoir)
	}
	`;

  let json = await store.dispatch("graphQl", {
    gql,
    variables: {
      destinationAbattoir: destinationAbattoir
    }
  });
  console.log(JSON.stringify(json));
}

export async function datasetsExists(datasetIds: string[]) {
  let gql = `query datasetsExists($datasetIds: [String!]!) {
    datasetsExists(datasetIds: $datasetIds)
  }`;
  let json = await store.dispatch("graphQl", {
    gql,
    variables: { datasetIds }
  });
  console.log(JSON.stringify(json));
  return json?.data?.datasetsExists ?? [];
}

export async function getDatasetExists(datasetId: string): Promise<boolean> {
  let gql = `query datasetExists($datasetId: String!) {
    datasetExists(datasetId: $datasetId)
  }`;
  let json = await store.dispatch("graphQl", {
    gql,
    variables: { datasetId }
  });
  console.log(JSON.stringify(json));
  return json?.data?.datasetExists ?? false;
}

export async function getDatasetTableExists(datasetId: string, tableId: string): Promise<boolean> {
  let gql = `query datasetTableExists($datasetId: String!, $tableId: String!) {
    datasetTableExists(datasetId: $datasetId, tableId: $tableId)
  }`;
  let json = await store.dispatch("graphQl", {
    gql,
    variables: { datasetId, tableId }
  });
  console.log(JSON.stringify(json));
  return json?.data?.datasetTableExists ?? false;
}

export async function isDatasetTableSchemaUpdated(datasetId: string, tableId: string): Promise<boolean> {
  let gql = `query isDatasetTableSchemaUpdated($datasetId: String!, $tableId: String!) {
    isDatasetTableSchemaUpdated(datasetId: $datasetId, tableId: $tableId)
  }`;
  let json = await store.dispatch("graphQl", {
    gql,
    variables: { datasetId, tableId }
  });
  console.log(JSON.stringify(json));
  return json?.data?.isDatasetTableSchemaUpdated ?? false;
}

export async function createDatasetTable(datasetId: string, type: "abattoir" | "feedlot") {
  try {
    let gql = `mutation createDatasetTables($datasetId: String!, $type: String!) {
      createDatasetTables(datasetId: $datasetId, type: $type) {
        dataset
        tables {
          name
        }
        createdTables
      }
    }`;

    let json = await store.dispatch("graphQl", { gql, variables: { datasetId: datasetId, type: type } });

    //console.log(JSON.stringify(json));
    console.log("createDatasetTable");
    console.dir(json);
  } catch (err) {
    console.log("error: " + err);
    throw err;
  }
}

export async function getPublicCert(registryId: string) {
  try {
    let gql = `query getPublicCert($registryId: String!) {
			getPublicCert(registryId: $registryId)
		}`;

    let json = await store.dispatch("graphQl", {
      gql,
      variables: { registryId }
    });

    console.log(JSON.stringify(json));
    return json.data.getPublicCert;
  } catch (err) {
    console.log("error: " + err);
    throw err;
  }
}

export async function getPrivateKeyCert(registryId: string) {
  try {
    let gql = `query getPrivateKeyCert($registryId: String!) {
			getPrivateKeyCert(registryId: $registryId)
		}`;

    let json = await store.dispatch("graphQl", {
      gql,
      variables: { registryId }
    });

   
    return json.data.getPrivateKeyCert;
  } catch (err) {
    console.log("error: " + err);
    throw err;
  }
}
