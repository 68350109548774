import { camelCase, getProjectId } from ".";
import { getGcp } from "@gigalot/utils";
import store from "@/store";
import * as Models from "@gigalot/data-models";
import { mqttDeviceId } from "./mqtt";

// MQTT Device ID example:
// GigalotPtyLtdDolanFeedlotFeedlot_1594899356510-8a6cff73-22ef-492d-8403-cd22158d3b86.ProcessingStation.1

function getGuid(mqttDeviceId: string): string {
  if (mqttDeviceId.split(".").length !== 3) throw Error("mqttDevice should have two fullstops. It should be of the form <gcp>.<type>.<num>");

  if (mqttDeviceId.split(".")[0].split("_").length !== 2) throw Error("gcp must have exactly one underscore");

  const guid = mqttDeviceId.split(".")[0].split("_")[1];

  return guid;
}

function getHoldingCompany(mqttDeviceId: string, holdingCompanies: Models.HoldingCompany[]) {
  const guid = getGuid(mqttDeviceId);
  for (const hc of holdingCompanies) {
    const feedlot = hc.feedlots.find((fl) => fl.guid === guid);
    if (feedlot) return hc;
  }
  return undefined;
}

function getFeedlotAndHoldingCompanyFromMqttDeviceId(mqttDeviceId: string) {
  const guid = getGuid(mqttDeviceId);
  const holdingCompanies: Models.HoldingCompany[] = store.getters["getField"]("holdingCompanies");
  for (const hc of holdingCompanies) {
    const fl = hc.feedlots.find((fl) => fl.guid === guid);
    if (fl) return { holdingCompany: hc, feedlot: fl };
  }
  //return undefined;
  throw Error(`No feedlot and holding company found for MQTT device id ${mqttDeviceId}`);
}

function getFeedlotAndHoldingCompany(o: FeedlotHoldingCompany | MqttDeviceId) {
  let feedlot: Models.Feedlot;
  let holdingCompany: Models.HoldingCompany;
  if (typeof o === "object") {
    const t = o as FeedlotHoldingCompany;
    feedlot = t.feedlot;
    holdingCompany = t.holdingCompany;
  } else if (typeof o === "string") {
    const mqttDeviceId = o as MqttDeviceId;
    const t = getFeedlotAndHoldingCompanyFromMqttDeviceId(mqttDeviceId);
    if (!t) throw Error(`Could not get feedlot and holding company from ${mqttDeviceId}`);
    feedlot = t.feedlot;
    holdingCompany = t.holdingCompany;
  } else throw Error(`Could not get feedlot and holding company from ${JSON.stringify(o)}`);

  if (!feedlot || !holdingCompany) throw Error("feedlot and holding company are required.");

  return { feedlot, holdingCompany };
}

type FeedlotHoldingCompany = { feedlot: Models.Feedlot; holdingCompany: Models.HoldingCompany; };
type MqttDeviceId = string;

export function getConfigFromMqttId(mqttDeviceId: string) {
  //const { feedlot, holdingCompany } = getFeedlotAndHoldingCompanyFromMqttDeviceId(mqttDeviceId);

  const parts = mqttDeviceId.split(".");

  if (parts[1] === "OfficeServer") return configFileOffice(mqttDeviceId);
  if (parts[1] === "MixingServer") return configFileMixing(mqttDeviceId);
  if (parts[1] === "FeederServer") return configFileFeeder(mqttDeviceId);
  if (parts[1] === "WeatherStation") return configFileWeather(mqttDeviceId);
  if (parts[1] === "ProcessingStation") return configFileProxyProcessingStation({ mqttDeviceId });
  if (parts[1] === "GantryScanner") return {};
  if (parts[1] === "HandHeldScanner") return {};
  if (parts[1] === "Abattoir") return {};
}

export function configFileOffice(o: FeedlotHoldingCompany | MqttDeviceId) {
  const { feedlot, holdingCompany } = getFeedlotAndHoldingCompany(o);

  const gcp = getGcp(feedlot, holdingCompany);

  const config = {
    //processingStation: processingStation,
    //TODO: all processing stations
    environment: store.state.environment,
    port: 7766,
    location: {
      gcp: gcp,
      guid: feedlot.guid,
      name: feedlot.name,
    },
    holdingCompany: {
      guid: holdingCompany.guid,
      name: holdingCompany.name,
    },
    mqtt: {
      projectId: getProjectId(),
      registryId: "Office_Servers",
      deviceId: mqttDeviceId("office-server", gcp, feedlot.guid, 1), //this.mqttDeviceId("office-server"),
      region: "europe-west1",
      mqttCertFile: "./Config/mqtt.pem",
      privateKeyFile: "./Config/cert1.key",
      certificateFile: "./Config/cert1.crt.pem",
      updateFile: "./Config/update/update",
      mqttBridgeHostname: "mqtt.gigalot.systems",
      mqttBridgePort: 8883,
      username: "paul",
      password: "samsung",
    },
    mongourl: `mongodb://root:password@localhost:27017/gigalot-model-data?authSource=admin`,
  };

  return config;
}

export function configFileFeeder(o: FeedlotHoldingCompany | MqttDeviceId) {
  const { feedlot, holdingCompany } = getFeedlotAndHoldingCompany(o);

  const gcp = getGcp(feedlot, holdingCompany);

  const config = {
    projectID: getProjectId(),
    gcp: gcp,
    gps: { lat: 0, lng: 0 },
    location: feedlot.guid,
    kml: "",
    bucketName: `gigalot-cloud-${gcp.toLowerCase()}-feeder`,
    environment: store.state.environment,
    importSetup: false,
    mqtt: {
      projectId: getProjectId(),
      registryId: "Feeder_Servers",
      deviceId: mqttDeviceId("feeder-server", gcp, feedlot.guid, 1),
      region: "europe-west1",
      mqttCertFile: "./Config/mqtt.pem",
      privateKeyFile: "./Config/cert1.key",
      certificateFile: "./Config/cert1.crt.pem",
      updateFile: "./Config/update/update",
      mqttBridgeHostname: "mqtt.gigalot.systems",
      mqttBridgePort: 8883,
      username: "paul",
      password: "samsung",
    },
    mongourl: `mongodb://root:password@localhost:27017/gigalot-model-data?authSource=admin`,
  };

  return config;
}

export function configFileMixing(o: FeedlotHoldingCompany | MqttDeviceId) {
  const { feedlot, holdingCompany } = getFeedlotAndHoldingCompany(o);

  const gcp = getGcp(feedlot, holdingCompany);

  const config = {
    projectID: getProjectId(),
    gcp: gcp,
    location: feedlot.guid,
    bucketName: `gigalot-cloud-${gcp.toLowerCase()}-feeder`,
    mqtt: {
      projectId: getProjectId(),
      registryId: "Mixing_Servers",
      deviceId: mqttDeviceId("mixer-server", gcp, feedlot.guid, 1),
      region: "europe-west1",
      mqttCertFile: "./Config/mqtt.pem",
      privateKeyFile: "./Config/cert1.key",
      certificateFile: "./Config/cert1.crt.pem",
      mqttBridgeHostname: "mqtt.gigalot.systems",
      mqttBridgePort: 8883,
      updateFile: "./Config/update/update",
      configFile: "./Config/config.json",
      shouldOverwriteConfig: true,
      username: "paul",
      password: "samsung",
    },
  };

  return config;
}

export function configFileWeather(o: FeedlotHoldingCompany | MqttDeviceId) {
  const { feedlot, holdingCompany } = getFeedlotAndHoldingCompany(o);

  const gcp = getGcp(feedlot, holdingCompany);

  const config = {
    projectID: getProjectId(),
    gcp: gcp,
    gps: { lat: 0, lng: 0 },
    location: feedlot.guid,
    station_id: "63f4f8898885c2000177ac39",
    mqtt: {
      projectId: getProjectId(),
      registryId: "Weather_Stations",
      deviceId: mqttDeviceId("weather-station", gcp, feedlot.guid, 1),
      region: "europe-west1",
      mqttCertFile: "./Config/mqtt.pem",
      privateKeyFile: "./Config/cert1.key",
      certificateFile: "./Config/cert1.crt.pem",
      updateFile: "./Config/update/update",
      mqttBridgeHostname: "mqtt.gigalot.systems",
      mqttBridgePort: 8883,
      username: "paul",
      password: "samsung",
    },
  };

  return config;
}

export function configFileProxyProcessingStation(o: { mqttDeviceId: MqttDeviceId; }) {
  const { feedlot, holdingCompany } = getFeedlotAndHoldingCompany(o.mqttDeviceId);

  const getNum = () => parseInt(o.mqttDeviceId.split(".")[2]);

  const num = getNum();

  const gcp = getGcp(feedlot, holdingCompany);

  const config = {
    restfulProxy: {
      description: "HSL rest",
      origin: "*",
      proxyPort: "9000",
      targetAddress: "hsl",
      targetPort: "9004",
    },
    websocketProxies: [
      {
        description: "scale",
        proxyPort: "8002",
        targetAddress: "hsl",
        targetPort: "8006",
      },
      {
        description: "allflex",
        proxyPort: "8003",
        targetAddress: "hsl",
        targetPort: "8007",
      },
    ],
    GCP: gcp,
    location: feedlot.guid,
    projectID: getProjectId(),
    processingAppDownloadCacheHours: 24,
    ignoreBodyParser: false,
    mqtt: {
      projectId: getProjectId(),
      registryId: "Processing_Stations",
      deviceId: mqttDeviceId("processing-station", gcp, feedlot.guid, num),
      region: "europe-west1",
      mqttCertFile: "./Config/mqtt.pem",
      privateKeyFile: "./Config/cert1.key",
      certificateFile: "./Config/cert1.crt.pem",
      mqttBridgeHostname: "mqtt.gigalot.systems",
      mqttBridgePort: 8883,
      updateFile: "./Config/update/update",
      configFile: "./Config/config.json",
      shouldOverwriteConfig: true,
      username: "paul",
      password: "samsung",
    },
  };
  return config;
}

export function configFileProxyHandHeldScanner(o: { mqttDeviceId: MqttDeviceId; }) {
  const { feedlot, holdingCompany } = getFeedlotAndHoldingCompany(o.mqttDeviceId);

  const getNum = () => parseInt(o.mqttDeviceId.split(".")[2]);

  const num = getNum();

  const gcp = getGcp(feedlot, holdingCompany);

  const config = {
    restfulProxy: {
      description: "HSL rest",
      origin: "*",
      proxyPort: "9000",
      targetAddress: "hsl",
      targetPort: "9004",
    },
    websocketProxies: [
      {
        description: "rfid",
        proxyPort: "8004",
        targetAddress: "hsl",
        targetPort: "8044",
      },
    ],
    GCP: gcp,
    location: feedlot.guid,
    projectID: getProjectId(),
    processingAppDownloadCacheHours: 24,
    ignoreBodyParser: true,
    mqtt: {
      projectId: getProjectId(),
      registryId: "Handheld_Scanners",
      deviceId: mqttDeviceId("hand-held-scanner", gcp, feedlot.guid, num),
      region: "europe-west1",
      mqttCertFile: "./Config/mqtt.pem",
      privateKeyFile: "./Config/cert1.key",
      certificateFile: "./Config/cert1.crt.pem",
      mqttBridgeHostname: "mqtt.gigalot.systems",
      mqttBridgePort: 8883,
      updateFile: "./Config/update/update",
      configFile: "./Config/config.json",
      shouldOverwriteConfig: true,
      username: "paul",
      password: "samsung",
    },
  };
  return config;
}

export function configFileProxyGantryScanner(o: { mqttDeviceId: MqttDeviceId; }) {
  const { feedlot, holdingCompany } = getFeedlotAndHoldingCompany(o.mqttDeviceId);

  const getNum = () => parseInt(o.mqttDeviceId.split(".")[2]);

  const num = getNum();

  const gcp = getGcp(feedlot, holdingCompany);

  const config = {
    restfulProxy: {
      description: "HSL rest",
      origin: "*",
      proxyPort: "9000",
      targetAddress: "hsl",
      targetPort: "9004",
    },
    websocketProxies: [
      {
        description: "rfid",
        proxyPort: "8004",
        targetAddress: "hsl",
        targetPort: "8044",
      },
    ],
    GCP: gcp,
    location: feedlot.guid,
    projectID: getProjectId(),
    processingAppDownloadCacheHours: 24,
    ignoreBodyParser: true,
    mqtt: {
      projectId: getProjectId(),
      registryId: "Gantry_Scanners",
      deviceId: mqttDeviceId("gantry-scanner", gcp, feedlot.guid, num),
      region: "europe-west1",
      mqttCertFile: "./Config/mqtt.pem",
      privateKeyFile: "./Config/cert1.key",
      certificateFile: "./Config/cert1.crt.pem",
      mqttBridgeHostname: "mqtt.gigalot.systems",
      mqttBridgePort: 8883,
      updateFile: "./Config/update/update",
      configFile: "./Config/config.json",
      shouldOverwriteConfig: true,
      username: "paul",
      password: "samsung",
    },
  };
  return config;
}
