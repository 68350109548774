import Vue from "vue";
import Vuex, { ActionContext } from "vuex";
import createPersistedState from "vuex-persistedstate";
import user from "./modules/user";
import popup from "./modules/popup";
import * as packageJson from "../../package.json";

const vuexMapFields = require("vuex-map-fields");
const createHelpers = vuexMapFields.createHelpers;

Vue.use(Vuex);

export const { mapFields, mapMultiRowFields } = createHelpers({
  getterType: "getField",
  mutationType: "updateField"
});

type EnvType = "production" | "staging" | "testing" | "undetermined";

let productionUrl: string = "https://admin.gigalot.co.za";
let stagingUrl: string = "https://admin.gigalot.systems";
let testingUrl: string = "http://localhost:8081";

let backendProductionUrl: string = "https://backend.gigalot.co.za";
let backendStagingUrl: string = "https://backend.gigalot.systems";


class RootState {
  constructor() {
    if (window.origin === productionUrl) {
      console.log("Production environment detected.");
      this.environment = "production";
    } else if (window.origin === stagingUrl) {
      console.log("Staging (cloud) environment detected.");
      this.environment = "staging";
    } else {
      console.log("Testing (local) environment detected.");
      this.environment = "testing";
    }
  }

  environment: EnvType;
  storage: { [key: string]: any; } = {};
  version: string = packageJson.version;
  lightDarkMode: "light" | "dark" = "dark";
}

export default new Vuex.Store<RootState>({
  state: new RootState(),
  mutations: {
    /*
    mutation(state: State, payload: any) {
      //no async calls
      state.data = payload;
    }
    */
    environment(state: RootState, payload: EnvType) {
      state.environment = payload;
    },
    updateField(state: any, field: { path: string; value: any; }) {
      let uid = state.user.user.uid;
      state.storage[uid] = state.storage[uid] || {};
      vuexMapFields.updateField(state.storage[uid], field);
    },
    lightDarkMode(state: any, payload: "light" | "dark") {
      state.lightDarkMode = payload;
    },
    version(state: any) {
      state.version = packageJson.version;
    }
  },
  actions: {
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
    async graphQl(context: ActionContext<RootState, any>, o: { gql: string; variables?: { [key: string]: any; }; }) {
      let adminUrl = context.getters["adminUrl"]();

      let jwt = await context.dispatch("user/getOnlineIdToken", undefined, { root: true });

      let options: RequestInit = {
        method: "POST",
        headers: { "Content-Type": "application/json", Accept: "application/json", Authorization: "Bearer " + jwt },
        body: JSON.stringify({ query: o.gql, variables: o.variables })
      };
      let response = await fetch(`${adminUrl}/admin`, options);

      if (response.ok) {
        let json = await response.json();
        return json;
      } else {
        let errorJson = await response.json();
        let s = "";
        for (let error of errorJson.errors) {
          s += `\n${error.message}`;
        }
        throw Error("response not ok: " + s);
      }
    }
  },
  getters: {
    /*
    getter(state: State, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
    backendUrl(state: RootState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        switch (state.environment) {
          case "production":
            return backendProductionUrl;
          case "staging":
            return backendStagingUrl;
          case "testing":
            return backendStagingUrl;
          case "undetermined":
          default:
            throw Error("No environment detected! (Expected to be production, staging, or testing)");
        }
      };
    },
    adminUrl(state: RootState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        switch (state.environment) {
          case "production":
            return "https://europe-west1-gigalot-cloud.cloudfunctions.net/adminAppResolver";
          case "staging":
            return "https://europe-west1-gigalot-testing.cloudfunctions.net/adminAppResolver";
          case "testing":
            return "http://localhost:5001/gigalot-testing/europe-west1/adminAppResolver";
          case "undetermined":
          default:
            throw Error("No environment detected! (Expected to be production, staging, or testing)");
        }
      };
    },
    getField(state: any): any {
      let uid = state.user.user.uid;
      state.storage[uid] = state.storage[uid] || {};
      return vuexMapFields.getField(state.storage[uid]);
    },
    dark(state: any) {
      return () => {
        return state.lightDarkMode === "dark";
      };
    }
  },
  modules: {
    user,
    popup
  },
  plugins: [createPersistedState()]
});
