
import { mqttStreamDeviceState } from "@/helpers/mqtt";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mqttUpdateDevice, mqttResetDevice, mqttUpdateDeviceConfig, mqttGetDeviceConfig, mqttPushCertificate } from "@/helpers/mqtt";

import { getConfigFromMqttId } from "@/helpers/config-generator";

@Component
export default class MqttDeviceStatus extends Vue {
  @Prop() device: any;
  @Prop() streamState?: boolean;

  unsubscribeFunc: (() => void) | "" = "";

  lastFirestoreStateUpdateTime: number = 0; //Time Firestore document updated
  lastMqttStateUpdateTime: number = 0; //Time MQTT state was written to Firestore

  timeSinceFirestoreUpdate: number = 0;
  timeSinceMqttUpdate: number = 0;

  configDialog: boolean = false;
  activeConfig: string = "";

  intervalHandle?: any;

  mounted() {
    if (this.streamState) this.getState();
  }

  unmounted() {
    if (this.unsubscribeFunc) this.unsubscribeFunc();
    if (this.intervalHandle) clearInterval(this.intervalHandle);
  }

  async mqttConfig() {
    console.log("mqttConfig");
    this.activeConfig = await mqttGetDeviceConfig(this.device);
    this.configDialog = true;
    console.log(this.activeConfig);
  }

  async sendConfig() {
    if (!this.activeConfig) throw Error("No config.");

    const config = JSON.parse(this.activeConfig);

    const id = this.device.id;
    const parts = id.split(".");
    const gcp = parts[0].split("_")[0];
    const locationGuid = parts[0].split("_")[1];

    if (config.GCP !== gcp) throw Error(`GCP mismatch. GCP set to ${config.GCP} but should be ${gcp}`);
    if (config.location !== locationGuid) throw Error(`location mismatch. GCP set to ${config.location} but should be ${locationGuid}`);
    if (config.mqtt?.deviceId !== id) throw Error(`MQTT device id mistmatch, id set to ${config.mqtt?.deviceId} but should be ${id}`);

    mqttUpdateDeviceConfig(this.device.id, this.device.registry, "1", this.activeConfig);

    this.activeConfig = "";
    this.configDialog = false;
  }

  async generateConfig() {
    if (this.activeConfig) throw Error("Can not generate, already have config.");
    this.activeConfig = JSON.stringify(getConfigFromMqttId(this.device.id), null, 2);
  }

  mqttPushCertificate = mqttPushCertificate;

  mqttResetDevice = mqttResetDevice;

  mqttUpdateDevice() {
    if (!this.device?.state?.arch) {
      console.warn("No OS arch available for device, which is required to get the update URL. Get device's state first.");
      return;
    }
    mqttUpdateDevice(this.device.id, this.device.registry, this.device.arch);
  }

  get uiLastUpdated() {
    const since = Math.floor(this.timeSinceFirestoreUpdate / 1000);
    let ret = since > 0 ? `got doc snapshot ${since} seconds ago` : ``;
    if (ret.length && !this.device.state) ret += ", but no state";
    if (ret.length) ret = `(${ret})`;
    return ret;
  }

  getFreeSpace(item: any) {
    let freespace = item?.state?.disk?.free;
    if (freespace) {
      return Math.floor(freespace / 1024 / 1024 / 1024);
    } else return 0;
  }

  getState() {
    this.unsubscribeFunc = mqttStreamDeviceState(this.device, (timeStateUpdated, state) => {
      this.lastMqttStateUpdateTime = timeStateUpdated ?? 0;
      this.device.state = state;
      this.lastFirestoreStateUpdateTime = Date.now();
      if (!this.intervalHandle) {
        this.intervalHandle = setInterval(() => {
          this.timeSinceFirestoreUpdate = Date.now() - this.lastFirestoreStateUpdateTime;
          this.timeSinceMqttUpdate = Date.now() - this.lastMqttStateUpdateTime;
        }, 500);
      }
    });
  }
}

export { MqttDeviceStatus };
