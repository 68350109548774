
import { Component, Vue, Prop } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/dimssa-button.vue";
import { getDatasetExists, gqlDatasetTable } from "@/helpers/graphql";

//TODO: look for fields in current schema that do not match fields in latest schema (this would be a big problem!)
//TODO: show which fields in latest schema need to be added to current schema

let idCount: number = 0;

@Component({
  components: {
    DimssaButton,
  },
})
export default class BigQueryTable extends Vue {
  @Prop() datasetId?: string;
  @Prop() tableId?: string;

  datasetExists: string = "";
  tableExists: string = "";

  buttonStateDatasetExists: ButtonState = "ready";
  buttonStateTableExists: ButtonState = "ready";
  buttonStateGetDatasetTable: ButtonState = "ready";
  buttonStateUpdateSchema: ButtonState = "ready";
  buttonStateCreateTable: ButtonState = "ready";

  datasetTable: string | any = "";
  tableSchema: string | any = "";
  latestSchema: string | any = "";

  schemaMessages: string[] = [];
  schemasMatch: boolean = false;

  schemaToTreeViewItems(fields: any): any {
    const treeViewItems = [];
    if (!fields) return [];
    for (const field of fields) {
      treeViewItems.push({
        id: idCount++,
        name: `${field.name}, ${field.type}, ${field.mode}`,
        children: this.schemaToTreeViewItems(field.fields),
      });
    }
    return treeViewItems;
  }

  mounted() {
    if (!this.datasetId) throw Error("datasetId required");
    if (!this.tableId) throw Error("tableId required");
  }

  // async getDatasetTableIds() {
  //   let gql = `query datasetTables($datasetId: String!) {
  //     datasetTables(datasetId: $datasetId) {
  //       dataset
  //       tables {
  //         name
  //       }
  //     }
  //   }`;

  //   try {
  //     console.log(gql);
  //     let json = await this.$store.dispatch("graphQl", {
  //       gql
  //     });
  //     console.log(JSON.stringify(json));
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  async getDatasetTable() {
    try {
      this.buttonStateGetDatasetTable = "busy";
      console.log(gqlDatasetTable);
      let json = await this.$store.dispatch("graphQl", {
        gql: gqlDatasetTable,
        variables: { datasetId: this.datasetId, tableId: this.tableId },
      });
      console.log(JSON.stringify(json));
      if (json.data.datasetTable?.tables?.length !== 1) throw Error("getDatasetTable expects exactly 1 table.");
      this.datasetTable = json.data.datasetTable;
      this.tableSchema = this.datasetTable.tables[0].tableSchema;
      this.latestSchema = this.datasetTable.tables[0].latestSchema;
      this.compareSchema();
      this.buttonStateGetDatasetTable = "success";
    } catch (err) {
      console.log(err);
      this.buttonStateGetDatasetTable = "error";
    }
  }

  compareSchema() {
    this.schemaMessages = [];
    const self = this;
    const _compareSchema = function (tableFields: any, latestFields: any, prefix: string) {
      for (const field of tableFields) {
        const fieldInLatestSchema = latestFields.find((f: any) => f.name === field.name);
        if (!fieldInLatestSchema) self.schemaMessages.push(`!!! ${field.name} found in table schema but not in latest schema !!!`);
        else if (field.type !== fieldInLatestSchema.type)
          self.schemaMessages.push(`${prefix}.${field.name} has type ${field.type} in BQ but latest schema has type ${fieldInLatestSchema.type}`);
        else if (field.mode !== fieldInLatestSchema.mode)
          self.schemaMessages.push(`${prefix}.${field.name} has mode ${field.mode} in BQ but latest schema has mode ${fieldInLatestSchema.mode}`);
        else {
          field.fields = field.fields ?? [];
          fieldInLatestSchema.fields = fieldInLatestSchema.fields ?? [];
          if (field.fields.length === fieldInLatestSchema.fields.length) _compareSchema(field.fields, fieldInLatestSchema.fields, `${prefix}${field.name}.`);
          else {
            const f = (l?: number) => `${l ?? "0"}`;
            self.schemaMessages.push(
              `${prefix}.${field.name} has ${f(field.fields?.length)} fields but latest schema has ${f(fieldInLatestSchema.fields?.length)}`
            );
          }
        }
      }

      for (const field of latestFields) {
        const fieldInTableSchema = tableFields.find((f: any) => f.name === field.name);
        if (!fieldInTableSchema) self.schemaMessages.push(`${field.name} found in latest schema but not in table schema`);
        else if (field.type !== fieldInTableSchema.type)
          self.schemaMessages.push(`${prefix}.${field.name} has type ${field.type} in latest but BQ schema has type ${fieldInTableSchema.type}`);
        else if (field.mode !== fieldInTableSchema.mode)
          self.schemaMessages.push(`${prefix}.${field.name} has mode ${field.mode} in latest but BQ schema has mode ${fieldInTableSchema.mode}`);
        else {
          field.fields = field.fields ?? [];
          fieldInTableSchema.fields = fieldInTableSchema.fields ?? [];
          if (field.fields.length === fieldInTableSchema.fields.length) _compareSchema(field.fields, fieldInTableSchema.fields, `${prefix}${field.name}.`);
          else {
            const f = (l?: number) => `${l ?? "0"}`;
            self.schemaMessages.push(`${prefix}.${field.name} has ${f(field.fields?.length)} fields but BQ schema has ${f(fieldInTableSchema.fields?.length)}`);
          }
        }
      }

      self.schemasMatch = self.schemaMessages.length === 0;
    };

    _compareSchema(this.tableSchema.fields, this.latestSchema.fields, "");
  }

  async getDatasetExists() {
    this.buttonStateDatasetExists = "busy";
    try {
      if (!this.datasetId) throw Error("No datasetId");
      const datasetExists = await getDatasetExists(this.datasetId);
      if (datasetExists) {
        this.datasetExists = "Yes";
        this.buttonStateDatasetExists = "success";
      } else {
        this.datasetExists = "No";
        this.buttonStateDatasetExists = "error";
      }
    } catch (err) {
      console.log(err);
      this.buttonStateDatasetExists = "error";
      this.datasetExists = "Error";
    }
  }

  async getTableExists() {
    this.buttonStateTableExists = "busy";
    let gql = `query datasetTableExists($datasetId: String!, $tableId: String!) {
      datasetTableExists(datasetId: $datasetId, tableId: $tableId)
    }`;

    try {
      console.log(gql);
      let json = await this.$store.dispatch("graphQl", {
        gql,
        variables: { datasetId: this.datasetId, tableId: this.tableId },
      });
      console.log(JSON.stringify(json));
      if (json.data.datasetTableExists) {
        this.tableExists = "Yes";
        this.buttonStateTableExists = "success";
      } else {
        this.tableExists = "No";
        this.buttonStateTableExists = "error";
      }
    } catch (err) {
      console.log(err);
      this.buttonStateTableExists = "error";
      this.tableExists = "Error";
    }
  }

  async updateSchema() {
    this.buttonStateUpdateSchema = "busy";
    let gql = `mutation updateTableSchema($datasetId: String!, $tableId: String!) {
      updateTableSchema(datasetId: $datasetId, tableId: $tableId)
    }`;
    try {
      console.log(gql);
      let json = await this.$store.dispatch("graphQl", {
        gql,
        variables: { datasetId: this.datasetId, tableId: this.tableId },
      });
      console.log(JSON.stringify(json));
      this.buttonStateUpdateSchema = "success";
    } catch (err) {
      console.log(err);
      this.buttonStateUpdateSchema = "error";
    }
  }

  async createTable() {
    try {
      this.buttonStateCreateTable = "busy";
      let gql = `mutation createDatasetTable($datasetId: String!, $tableId: String!) {
        createDatasetTable(datasetId: $datasetId, tableId: $tableId)
      }`;
      let json = await this.$store.dispatch("graphQl", {
        gql,
        variables: { datasetId: this.datasetId, tableId: this.tableId },
      });
      console.log("createTable");
      console.dir(json);

      this.buttonStateCreateTable = "success";
    } catch (err) {
      console.error(err);
      this.buttonStateCreateTable = "error";
      throw err;
    }
  }
}
