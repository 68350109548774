
import { Component, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/dimssa-button.vue";
import store from "@/store";

@Component({
  components: {
    DimssaButton,
  },
})
export default class Home extends Vue {
  async resubscribe() {
    let options: RequestInit = {
      method: "GET",
      headers: { Accept: "application/json" },
      mode: "no-cors",
    };
    let response = await fetch(`${store.getters["backendUrl"]()}/resubscribe`, options);
    console.log(response);
  }

  async test() {
    console.log("test()");

    let gql = `mutation testIam($x: String!) {
			testIam(x: $x)
		}`;

    let json = await store.dispatch("graphQl", {
      gql,
      //variables: { deviceId: mqttDeviceId(what, gcp, guid, num), registryId: mqttDeviceRegistryId(what) }
      variables: { x: "123" },
    });

    console.log(JSON.stringify(json));
    //return json;
  }
}
