
import { Component, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/dimssa-button.vue";
import { datasetsExists, getDatasetExists, getDatasetTableExists, isDatasetTableSchemaUpdated } from "@/helpers/graphql";

@Component({
  components: {
    DimssaButton,
  },
})
export default class BigQueryDatasets extends Vue {
  gotTableIds: boolean = false;
  message: string = "Fetching dataset and table names.";
  datasetTableIds: { datasetId: string; tables: { name: string }[] }[] = [];
  expandedDatasets: any[] = [];
  updateKey: number = 0;
  updateInnerTableKey: number = 0;
  //updateKeyTableExists: number = 0;

  datasetExistsButtonStates: { [datasetId: string]: ButtonState } = {};
  datasetTableExistsButtonStates: { [datasetId: string]: { [tableId: string]: ButtonState } } = {};

  lookForProblemsButtonState: ButtonState = "ready";
  fixProblemsButtonState: ButtonState = "disabled";

  async mounted() {
    //console.log("querying allDatasetTables...");
    // let gql = `query allDatasetTables {
    //   allDatasetTables {
    //     dataset
    //     tables {
    //       name
    //       tableSchema {
    //         ...schema
    //       }
    //       latestSchema {
    //         ...schema
    //       }
    //     }
    //   }
    // } fragment schema on Schema {
    //   ${this.gqlSchemaFragmentHelper(10)}
    // }
    // fragment schemaFields on SchemaField {
    //   name
    //   type
    //   mode
    // }`;
    await this.getDatasetTableIds();
  }

  async getDatasetTableIds() {
    let gql = `query allDatasetTableIds {
      allDatasetTableIds {
        dataset
        tables {
          name
        }
      }
    }`;

    try {
      console.log(gql);
      let json = await this.$store.dispatch("graphQl", {
        gql,
      });
      //console.log(JSON.stringify(json));
      console.dir(json);
      if (!json.data.allDatasetTableIds) throw Error("No data.allDatasetTableIds");
      this.datasetTableIds = [];
      for (const d of json.data.allDatasetTableIds) {
        this.datasetExistsButtonStates[d.dataset] = "ready";
        //this.clickDatasetExists(d.dataset);
        this.datasetTableIds.push({
          datasetId: d.dataset,
          tables: d.tables.map((t: any) => {
            if (!this.datasetTableExistsButtonStates[d.dataset]) this.datasetTableExistsButtonStates[d.dataset] = {};
            this.datasetTableExistsButtonStates[d.dataset][t.name] = "ready";
            return { name: t.name };
          }),
        });
      }
      this.gotTableIds = true;
    } catch (err) {
      console.log(err);
      this.message = `Error: ${err}`;
    }
  }

  gqlSchemaFragmentHelper(depth: number): string {
    if (depth <= 0) return "fields { ...schemaFields }";
    return `fields { ...schemaFields ${this.gqlSchemaFragmentHelper(depth - 1)} }`;
  }

  viewTable(datasetId: string, tableId: string) {
    // console.log(`datasetId: ${datasetId}`);
    // console.log(`tableId: ${tableId}`);
    // console.log(JSON.stringify(datasetId));
    this.$router.push({ name: "bigquery-table", params: { datasetId, tableId } });
  }

  async clickDatasetExists(datasetId: string) {
    this.datasetExistsButtonStates[datasetId] = "busy";
    this.updateKey++;
    try {
      const datasetExists = await getDatasetExists(datasetId);
      if (datasetExists) this.datasetExistsButtonStates[datasetId] = "success";
      else this.datasetExistsButtonStates[datasetId] = "error";
    } catch (err) {
      console.error(err);
      this.datasetExistsButtonStates[datasetId] = "error";
    }
    this.updateKey++;
  }

  async clickDatasetTableCheck(datasetId: string, tableId: string) {
    this.datasetTableExistsButtonStates[datasetId][tableId] = "busy";
    this.updateInnerTableKey++;
    let tableExists;
    try {
      tableExists = await getDatasetTableExists(datasetId, tableId);
      if (!tableExists) this.datasetTableExistsButtonStates[datasetId][tableId] = "error";
      else {
        const schemaUpdated = await isDatasetTableSchemaUpdated(datasetId, tableId);
        if (schemaUpdated) this.datasetTableExistsButtonStates[datasetId][tableId] = "success";
        else this.datasetTableExistsButtonStates[datasetId][tableId] = "error";
      }
    } catch (err) {
      console.error(err);
      this.datasetTableExistsButtonStates[datasetId][tableId] = "error";
    }
    this.updateInnerTableKey++;
  }

  problemItems: any = [];
  async lookForProblems() {
    try {
      this.problemItems = [];
      this.lookForProblemsButtonState = "busy";

      //Look for datasets that do not exists
      const datasetIds = this.datasetTableIds.map((dt) => dt.datasetId);
      const existingDatasetIds: string[] = await datasetsExists(datasetIds);
      const nonExistingDatasetIds = datasetIds.filter((di) => !existingDatasetIds.find((edi) => edi === di));
      this.problemItems.push(...nonExistingDatasetIds.map((nedi) => ({ type: "non-existing-dataset", datasetId: nedi, buttonState: "ready" })));

      //Look through existing datasets for problem tables (tables that either don't exist or have outdated schema)
      for (const datasetId of existingDatasetIds) {
        const tables = this.datasetTableIds.find((dt) => dt.datasetId === datasetId)?.tables ?? [];
        for (const table of tables) {
          const tableId = table.name;
          if (!(await getDatasetTableExists(datasetId, tableId)))
            this.problemItems.push({ type: "non-existing-table", datasetId, tableId, buttonState: "ready" });
          else if (!(await isDatasetTableSchemaUpdated(datasetId, tableId)))
            this.problemItems.push({ type: "outdated-table-schema", datasetId, tableId, buttonState: "ready" });
        }
      }

      this.lookForProblemsButtonState = "success";
      this.fixProblemsButtonState = "ready";
    } catch (err) {
      console.error(err);
      this.lookForProblemsButtonState = "error";
    }
  }

  async fixProblems() {
    try {
      this.fixProblemsButtonState = "busy";
      for (const problemItem of this.problemItems) {
        if (problemItem.type === "non-existing-dataset") await this.createDataset(problemItem);
        if (problemItem.type === "non-existing-table") await this.createTable(problemItem);
        if (problemItem.type === "outdated-table-schema") await this.updateSchema(problemItem);
      }
      this.fixProblemsButtonState = "success";
    } catch (err) {
      console.error(err);
      this.fixProblemsButtonState = "error";
    }
  }

  async createDataset(item: { datasetId: string; buttonState: ButtonState }) {
    throw Error("TODO: fix first, type 'abattoir' or 'feedlot' needs to be used");
    try {
      item.buttonState = "busy";
      let gql = `mutation createDatasetTables($datasetId: String!) {
        createDatasetTables(datasetId: $datasetId) {
          dataset
          tables {
            name
          }
        }
      }`;
      let json = await this.$store.dispatch("graphQl", {
        gql,
        variables: { datasetId: item.datasetId },
      });
      console.log("createDataset");
      console.dir(json);
      item.buttonState = "success";
    } catch (err) {
      console.error(err);
      item.buttonState = "error";
      throw err;
    }
  }

  async createTable(item: { datasetId: string; tableId: string; buttonState: ButtonState }) {
    try {
      item.buttonState = "busy";
      let gql = `mutation createDatasetTable($datasetId: String!, $tableId: String!) {
        createDatasetTable(datasetId: $datasetId, tableId: $tableId)
      }`;
      let json = await this.$store.dispatch("graphQl", {
        gql,
        variables: { datasetId: item.datasetId, tableId: item.tableId },
      });
      console.log("createTable");
      console.dir(json);

      item.buttonState = "success";
    } catch (err) {
      console.error(err);
      item.buttonState = "error";
      throw err;
    }
  }

  async updateSchema(item: { datasetId: string; tableId: string; buttonState: ButtonState }) {
    try {
      item.buttonState = "busy";
      let gql = `mutation updateTableSchema($datasetId: String!, $tableId: String!) {
        updateTableSchema(datasetId: $datasetId, tableId: $tableId)
      }`;
      let json = await this.$store.dispatch("graphQl", {
        gql,
        variables: { datasetId: item.datasetId, tableId: item.tableId },
      });
      console.log("updateSchema");
      console.dir(json);
      item.buttonState = "success";
    } catch (err) {
      console.error(err);
      item.buttonState = "error";
      throw err;
    }
  }
}
