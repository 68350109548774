
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import store, { mapFields, mapMultiRowFields } from "@/store";
import lodash from "lodash";
import * as helpers from "@/helpers";
import { updateDestinationAbattoir } from "@/helpers/graphql";

@Component({
  components: {
    DimssaButton,
  },
  computed: {
    ...mapFields(["destinationAbattoir.name", "destinationAbattoir.gcp", "destinationAbattoir.vat"]),
  },
})
export default class Abattoir extends Vue {
  saveButtonState: ButtonState = "ready";
  async save() {
    try {
      this.saveButtonState = "busy";
      let destinationAbattoir = this.$store.getters["getField"]("destinationAbattoir");
      await updateDestinationAbattoir(destinationAbattoir);
      this.saveButtonState = "success";
    } catch (err) {
      console.error(`Error saving Destination Abattoir`);
      console.error(err);
      this.saveButtonState = "error";
    }
  }
}
