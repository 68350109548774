
import { Component, Vue, Watch } from "vue-property-decorator";
import wb from "./registerServiceWorker";

@Component({
  components: {}
})
export default class App extends Vue {
  async created() {
    if (wb)
      wb.addEventListener("waiting", async () => {
        //Update is ready, waiting
        console.log("workbox: update is ready");
        if (wb) {
          console.log('wb.messageSW({ type: "SKIP_WAITING" })');
          await wb.messageSW({ type: "SKIP_WAITING" });
          window.location.reload(true);
        } else throw Error("No wb to messageSW SKIP_WAITING");
        // this.$store.commit("popup/displayYesNo", {
        //   message: "An update is available. Would you like to update?",
        //   yesAction: async () => {
        //     if (wb) {
        //       await wb.messageSW({ type: "SKIP_WAITING" });
        //       window.location.reload(true);
        //     } else throw Error("No wb to messageSW SKIP_WAITING");
        //   }
        // });
      });
    else console.log("App.vue created(): no wb found");
  }

  mounted() {
    this.$vuetify.theme.dark = this.$store.getters["dark"]();
  }

  toggleLightDarkMode() {
    this.$store.commit("lightDarkMode", this.$store.state.lightDarkMode === "light" ? "dark" : "light");
    this.$vuetify.theme.dark = this.$store.getters["dark"]();
  }
}
