
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import store, { mapFields, mapMultiRowFields } from "@/store";
import lodash from "lodash";
import * as helpers from "@/helpers";
import { downloadObjectAsJson, downloadObjectAsText } from "@/helpers/download";
import { camelCase, getProjectId } from "@/helpers";
import { mqttDeviceId, mqttDeviceRegistryId, mqttPrivateKey, mqttPushCertificate, mqttRegisterDevice } from "@/helpers/mqtt";
import { getGcp } from "@gigalot/utils";
import { updateHoldingCompany, getPrivateKeyCert, getPublicCert } from "@/helpers/graphql";
import JSZip from "jszip";
import { createServiceAccount } from "@/helpers/index";
import { eXgetGcp } from "@/helpers/index";
import { MqttDeviceStatus } from "@/components/MqttDeviceStatus.vue";
import { configFileProxyProcessingStation } from "@/helpers/config-generator";

@Component({
  components: {
    DimssaButton,
    MqttDeviceStatus,
  },
  computed: {
    ...mapFields(["processingStation.description"]),
    //...mapMultiRowFields(["processingStation.sortingGates"])
  },
})
export default class ProcessingStation extends Vue {
  saveButtonState: ButtonState = "ready";

  mqttDevice = {};

  created() {
    this.mqttDevice = {
      id: this.mqttDeviceId(),
      registry: "Processing_Stations",
      state: undefined,
    };
  }

  mqttDeviceId() {
    const what: string = "processing-station";
    const feedlot: Models.Feedlot = this.$store.getters["getField"]("feedlot") as Models.Feedlot;
    const processingStations: Models.ProcessingStation[] = feedlot.processingStations;
    const index = processingStations.findIndex((pr) => pr.guid === this.processingStation.guid);
    let num = index + 1;
    if (index < 0) {
      //user might be busy with a new processing station that has not been saved yet.
      num = processingStations.length + 1;
    }
    return mqttDeviceId(what, this.getGcp(), this.getGuid(), num);
  }

  get processingStation(): Models.ProcessingStation {
    return this.$store.getters["getField"]("processingStation");
  }

  get serviceAccountName() {
    return `${eXgetGcp().slice(0, 29)}`;
  }

  deleteSortingGate(index: number) {
    const gates = this.processingStation.sortingGates;
    this.processingStation.sortingGates = [...gates.slice(0, index), ...gates.slice(index + 1)];
    this.$store.commit("updateField", { path: "processingStation", value: this.processingStation });
  }

  addGate() {
    let processingStation: Models.ProcessingStation = this.$store.getters["getField"]("processingStation");
    if (!processingStation.sortingGates) processingStation.sortingGates = [];
    const i = processingStation.sortingGates.length + 1;
    processingStation.sortingGates.push({ id: `${i}`, description: `Gate ${i}`, gateKraalId: `Sorting Pen ${i}` });
    this.$store.commit("updateField", { path: "processingStation", value: processingStation });
  }

  async save() {
    try {
      this.saveButtonState = "busy";
      const holdingCompany = this.$store.getters["getField"]("holdingCompany");
      let feedlot = this.$store.getters["getField"]("feedlot");
      if (!feedlot.processingStations) feedlot.processingStations = [];
      helpers.addToListGuids(feedlot.processingStations, this.processingStation);
      helpers.addToListGuids(holdingCompany.feedlots, feedlot);
      this.$store.commit("updateField", { path: "feedlot", value: feedlot });
      await updateHoldingCompany(holdingCompany);
      this.saveButtonState = "success";
    } catch (err) {
      console.error(`Error saving Processing Station`);
      console.error(err);
      this.saveButtonState = "error";
    }
  }

  getProcessingConfigFilename(feedlot: any, processingStation: any) {
    const holdingCompany: Models.HoldingCompany = this.$store.getters["getField"]("holdingCompany") as Models.HoldingCompany;
    let filename = `config_ProcessingStation-${camelCase(processingStation.description)}`;
    filename += `_${feedlot.typename}-${camelCase(feedlot.name)}`;
    filename += `_${holdingCompany.typename}-${camelCase(holdingCompany.name)}`;
    return filename;
  }

  configFile(download = true) {
    const feedlot: Models.Feedlot = this.$store.getters["getField"]("feedlot") as Models.Feedlot;
    const processingStation = this.$store.getters["getField"]("processingStation");
    const filename = this.getProcessingConfigFilename(feedlot, processingStation);

    const json = {
      processingStation: {
        guid: processingStation.guid,
        description: processingStation.description,
      },
      location: {
        type: feedlot.typename,
        name: feedlot.name,
        guid: feedlot.guid,
      },
      hardware: {
        allflex: true,
      },
      gates: processingStation.sortingGates,
    };

    if (download) downloadObjectAsJson(json, filename);
    return json;
  }

  configFileProxy(download = true) {
    const json = configFileProxyProcessingStation({ mqttDeviceId: this.mqttDeviceId() });
    if (download) downloadObjectAsJson(json, "config");
    return json;
  }

  getGuid() {
    const feedlot: Models.Feedlot = this.$store.getters["getField"]("feedlot") as Models.Feedlot;
    return feedlot.guid;
  }

  getGcp() {
    const feedlot: Models.Feedlot = this.$store.getters["getField"]("feedlot") as Models.Feedlot;
    let holdingCompany = this.$store.getters["getField"]("holdingCompany");
    return getGcp(feedlot, holdingCompany);
  }

  async createServiceAccount(download = true) {
    try {
      let data = await createServiceAccount(this.serviceAccountName, download);
      return data;
    } catch (err) {
      console.log("error: " + err);
      throw err;
    }
  }

  async downloadArchive() {
    const feedlot: Models.Feedlot = this.$store.getters["getField"]("feedlot") as Models.Feedlot;
    const processingStation = this.$store.getters["getField"]("processingStation");

    var zip = new JSZip();
    zip.file("cert1.crt.pem", await getPublicCert(mqttDeviceRegistryId("processing-station")));
    zip.file("cert1.key", await getPrivateKeyCert(mqttDeviceRegistryId("processing-station")));

    zip.file("service-account.json", await this.createServiceAccount(false));
    //zip.file("mqtt.pem", await this.mqttPrivateKey(false));
    zip.file("config.json", JSON.stringify(this.configFileProxy(false)));
    zip.file(this.getProcessingConfigFilename(feedlot, processingStation), JSON.stringify(this.configFile(false)));

    zip.generateAsync({ type: "blob" }).then(function (content) {
      var downloadAnchorNode = document.createElement("a");

      let dataURL = (window.URL || window.webkitURL).createObjectURL(content);
      downloadAnchorNode.setAttribute("href", dataURL);
      downloadAnchorNode.setAttribute("download", `${feedlot.name}-${processingStation.description}-config.zip`);
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  }
}
