import store from "@/store";
import * as Models from "@gigalot/data-models";

// let mapFromFeedLotToHoldingCompany: { [guid: string]: Models.HoldingCompany; } = {};

// export function getMapFromFeedLotToHoldingCompany() {
//   return mapFromFeedLotToHoldingCompany;
// }

export async function getHoldingCompanies() {
  const gql = `{
    holdingCompanies {
      ...companyFields
      feedlots {
        ...companyFields
        ranches {
          ...companyFields
        }
        processingStations {
          typename
          guid
          description
          sortingGates {
            id
            description
            gateKraalId
          }
        }
        gantryScanners {
          typename
          guid
          description
        }
        handHeldScanners {
          typename
          guid
          description
        }
        destinations {
          type
          locationGuid
          holdingCompanyGuid
          locationName
          holdingCompanyName
          gcp
        }
      }
      ranches {
        ...companyFields
      }
      abattoirs {
        ...companyFields
      }
    }
  }
  fragment companyFields on ICompany {
    typename
    guid
    gcp
    name
    alias
    vat
    gps {
      lat
      lon
    }
  }`;

  const json = await store.dispatch("graphQl", { gql });

  const holdingCompanies: Models.HoldingCompany[] = json.data.holdingCompanies;
  // mapFromFeedLotToHoldingCompany = {};

  // for (const hc of holdingCompanies) {
  //   for (const fl of hc.feedlots) {
  //     mapFromFeedLotToHoldingCompany[fl.guid] = hc;
  //   }
  // }

  return holdingCompanies;
}
