import lodash from "lodash";
import store from "@/store";
import { downloadObjectAsJson } from "./download";
import { getGcp } from "@gigalot/utils";

//adds or replaces an item, if item in list is found with same guid then item is replaced. Otherwise the new item is added
export function addToListGuids(list: (any & { guid: string; })[], item: any & { guid: string; }) {
  const index = lodash.findIndex(list, { guid: item.guid }); //index is -1 if guid is not found in the array
  if (index < 0) list.push(item);
  else list[index] = item;
}

export function camelCase(s: string) {
  return s
    .split(/\s/) //split on spaces
    .filter(s => s.length) //remove empty strings
    .map(lodash.capitalize) //capitalize all strings
    .join(""); //join strings together
}

export const getProjectId = () => (store.state.environment === "production" ? "gigalot-cloud" : "gigalot-testing");

export async function createServiceAccount(serviceAccountName: string, download = true) {
  try {
    const gql = `mutation createServiceAccount($gcp: String!, $location: String!) {
      createServiceAccount(gcp: $gcp, location: $location)
    }`;

    const json = await store.dispatch("graphQl", {
      gql,
      variables: { gcp: serviceAccountName, location: store.getters["getField"]("feedlot").guid },
    });
    const holdingCompany = store.getters["getField"]("holdingCompany");
    const feedlot = store.getters["getField"]("feedlot");
    const filename = `service-account-${camelCase(feedlot.name)}_${holdingCompany.typename}-${camelCase(holdingCompany.name)}`;

    if (download) downloadObjectAsJson(JSON.parse(json.data.createServiceAccount), filename);
    //console.log(JSON.stringify(json));
    return json.data.createServiceAccount;
  } catch (err) {
    console.log("error: " + err);
    throw err;
  }
}

export function eXgetGcp() {
  const holdingCompany = store.getters["getField"]("holdingCompany");
  const feedlot = store.getters["getField"]("feedlot");
  return getGcp(feedlot, holdingCompany);
}
