
import { Component, Vue, Watch } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/dimssa-button.vue";

@Component({
  components: {
    DimssaButton,
  },
})
export default class Users extends Vue {
  search: string = "";
  selected: any[] = [];
  headers: any[] = [
    {
      text: "Display Name",
      value: "displayName",
    },
    {
      text: "Email",
      value: "email",
    },
  ];
  users: any[] = [];

  mounted() {
    this.$store.dispatch("user/addFirebaseCallback", this.getUsers);
  }

  newUserDisplayName: string = "";
  newUserEmail: string = "";
  newUserError: boolean = false;

  async newUser() {
    this.newUserError = false;
    if (!this.newUserDisplayName || !this.newUserEmail) {
      this.newUserError = true;
      return;
    }

    try {
      let gql = `mutation updateUser($userData: UpdateUserInput!) {
        updateUser(userData: $userData)
      }
      `;
      let json = await this.$store.dispatch("graphQl", {
        gql,
        variables: {
          userData: {
            //uid: this.$store.getters["getField"]("user.uid"),
            displayName: this.newUserDisplayName,
            email: this.newUserEmail,
          },
        },
      });
      console.log(JSON.stringify(json));
      this.newUserDisplayName = "";
      this.newUserEmail = "";
      this.getUsers();
    } catch (err) {
      this.newUserError = true;
      console.error(err);
    }
  }

  async editUser() {
    //this.$store.commit("store", { user: this.selected[0] });
    this.$store.commit("updateField", { path: "user", value: this.selected[0] });
    this.$router.push({ name: "user" });
  }

  getUsersButtonState: ButtonState = "ready";

  async getUsers() {
    this.getUsersButtonState = "busy";
    try {
      let gql = `{
        users {
          uid
          displayName
          email
          apps {
            role
            guid
            accessRights
          }
        }
      }`;

      let json = await this.$store.dispatch("graphQl", { gql });
      console.log(JSON.stringify(json.data));
      this.users = json.data.users;
      this.getUsersButtonState = "success";
    } catch (err) {
      this.getUsersButtonState = "error";
      console.log("error: " + err);
    }
  }
}
