
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import store, { mapFields, mapMultiRowFields } from "@/store";
import lodash from "lodash";

@Component({
  components: {
    DimssaButton,
  },
  computed: {
    ...mapFields(["user.displayName", "user.email", "user.gcp"]),
  },
})
export default class User extends Vue {
  getCustomClaimsButtonState: ButtonState = "ready";
  setCustomClaimsButtonState: ButtonState = "disabled";

  customClaims: { [key: string]: string | string[] } = {};
  customClaimsString: string = "";

  async getCustomClaims() {
    this.getCustomClaimsButtonState = "busy";
    try {
      let gql = `query getCustomClaims($uid: String!) {
        getCustomClaims(uid: $uid)
      }
      `;
      let json = await this.$store.dispatch("graphQl", {
        gql,
        variables: {
          uid: this.user.uid,
        },
      });
      this.customClaims = JSON.parse(json.data.getCustomClaims);
      this.customClaimsString = JSON.stringify(this.customClaims);
      console.log(this.customClaims);
      this.getCustomClaimsButtonState = "success";
      this.setCustomClaimsButtonState = "ready";
    } catch (err) {
      this.getCustomClaimsButtonState = "error";
      console.log(err);
    }
  }

  async setCustomClaims() {
    console.log(this.user.uid);
    console.log(this.customClaims);
    this.setCustomClaimsButtonState = "busy";
    try {
      let gql = `mutation setCustomClaims($uid: String!, $claims: String!) {
        setCustomClaims(uid: $uid, claims: $claims)
      }
      `;
      let json = await this.$store.dispatch("graphQl", {
        gql,
        variables: {
          uid: this.user.uid,
          claims: this.customClaimsString,
        },
      });
      console.log(JSON.stringify(json));
      this.setCustomClaimsButtonState = "success";
    } catch (err) {
      this.setCustomClaimsButtonState = "error";
      //console.log(json);
      console.error(err);
    }
  }

  get user() {
    return this.$store.getters["getField"]("user");
  }

  getLocation(guid: string) {
    let holdingCompanies: Models.HoldingCompany[] = this.$store.getters["getField"]("holdingCompanies");
    for (let holdingCompany of holdingCompanies) {
      let company: any = lodash.find(holdingCompany.feedlots, { guid });
      if (company) {
        return `Feedlot: ${company.name}`;
      }
      company = lodash.find(holdingCompany.abattoirs, { guid });
      if (company) {
        return `Abattoir: ${company.name}`;
      }
    }
    throw Error(`Location for guid not found. guid: ${guid}`);
  }

  async mounted() {
    //this.$store.dispatch("user/addFirebaseCallback", this.getUsersRoles);
    let user = this.user;
    for (const app of user.apps) {
      try {
        this.userRoles.push({
          key: `${app.guid}${app.role}`,
          role: app.role,
          guid: app.guid,
          location: this.getLocation(app.guid),
          accessRights: app.accessRights,
        });
      } catch (err) {
        console.error(`Failed to add user role for ${app.role} at ${app.guid}: ${err}`);
      }
    }
  }

  async role() {
    if (this.selected.length) this.$store.commit("updateField", { path: "userRole", value: this.selected[0] });
    else this.$store.commit("updateField", { path: "userRole", value: undefined });
    this.$router.push({ name: "role" });
  }

  async save() {
    /*
    admin. auth. UpdateRequest
    disabled
    displayName
    email
    emailVerified
    password
    phoneNumber
    photoURL
    */

    let gql = `mutation updateUser($userData: UpdateUserInput!) {
        updateUser(userData: $userData)
      }
      `;
    let json = await this.$store.dispatch("graphQl", {
      gql,
      variables: {
        userData: {
          uid: this.$store.getters["getField"]("user.uid"),
          displayName: this.$store.getters["getField"]("user.displayName"),
          email: this.$store.getters["getField"]("user.email"),
        },
      },
    });
    console.log(JSON.stringify(json));
  }

  async removeRole() {
    if (this.selected.length) {
      let item = this.selected[0];
      let gql = `mutation removeUserRole($userApp: UserAppInput!) {
        removeUserRole(userApp: $userApp)
      }`;

      try {
        let json = await this.$store.dispatch("graphQl", {
          gql,
          variables: {
            userApp: {
              uid: this.user.uid,
              role: item.role,
              guid: item.guid,
            },
          },
        });
        lodash.remove(this.userRoles, this.selected[0]);
        this.$forceUpdate();
      } catch (err) {
        console.log(err);
      }
    }
  }

  selected: any[] = [];
  search: string = "";
  userRoles: any = [];
}
