
import { Component, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/dimssa-button.vue";
//import { MqttDeviceStatus } from "@/components/MqttDeviceStatus.vue";
import { IotDeviceStatus } from "@/components/IotDeviceStatus.vue";
//import moment from "moment";
import moment from "moment-timezone";
import { mqttUpdateDevice, mqttResetDevice, mqttUpdateDeviceConfig, mqttGetDeviceConfig } from "@/helpers/mqtt";
import firebase from "firebase/app";
import "firebase/firestore";
import { iotUpdateDevice } from "@/helpers/iot-pubsub";

@Component({
  components: {
    DimssaButton,
    IotDeviceStatus,
  },
})
export default class Servers extends Vue {
  mounted() {
    this.loading = true;
    this.getMqttDevices();
  }

  searchDeviceId: string = "";

  activeConfig = "";
  activeItem: any = {};
  configDialog = false;
  deviceHeaders: any[] = [
    {
      text: "Registry",
      value: "registry",
    },
    {
      text: "Device",
      value: "id",
    },
    {
      text: "MQTT State Updated",
      value: "timeStateUpdated",
    },
  ];
  devices: any = [];

  moment = moment;
  loading = false;
  getServerStatus(lastHeartbeatTime: any) {
    //TODO: get time Firestore was last updated
    if (lastHeartbeatTime && lastHeartbeatTime.seconds) {
      return Date.now() / 1000 - lastHeartbeatTime.seconds < 180;
    } else return 0;
  }
  sanitize(id: string) {
    return id.slice(0, id.indexOf("_")) + "..." + id.slice(id.indexOf("."), id.length);
  }

  sendUpdate(item: any) {
    if (!item?.state?.arch) {
      console.warn("No OS arch available for device, which is required to get the update URL. Get device's state first.");
      return;
    }
    //mqttUpdateDevice(item.id, item.registry, item.arch);
    iotUpdateDevice(item.id, item.registry, item.arch);
  }

  reset(item: any) {
    //mqttResetDevice(item.id, item.registry);
  }

  // async getMqttDeviceConfig(item: any) {
  //   this.activeConfig = await mqttGetDeviceConfig(item);
  //   this.activeItem = item;
  //   this.configDialog = true;
  // }

  closeConfigDialog() {
    this.configDialog = false;
    this.activeConfig = "";
    this.activeItem = {};
  }

  // sendConfig() {
  //   let dadta = this.activeConfig;
  //   mqttUpdateDeviceConfig(this.activeItem.id, this.activeItem.registry, "1", dadta);
  //   this.closeConfigDialog();
  // }

  getFreeSpace(item: any) {
    let freespace = item?.state?.disk?.free;
    if (freespace) {
      return Math.floor(freespace / 1024 / 1024 / 1024);
    } else return 0;
  }

  async getMqttDevices() {
    let gql = `{
        getMqttDevices {
          id
          registry
          state
          timeStateUpdated
        }
      }`;

    console.log("gql getMqttDevices", gql);
    let json = await this.$store.dispatch("graphQl", { gql });

    this.devices = json.data.getMqttDevices.map((d) => ({ ...d, state: d.state ? JSON.parse(d.state) : undefined, timeStateUpdated: d.timeStateUpdated }));

    this.loading = false;
  }
}
