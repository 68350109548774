
import { Component, Vue, Watch, Prop, Model } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/dimssa-button.vue";
import { getHoldingCompanies } from "@/helpers/holding-companies";
import * as Models from "@gigalot/data-models";

@Component({
  components: {
    DimssaButton,
  },
})
export default class HoldingCompanies extends Vue {
  search: string = "";
  selected: any[] = [];
  headers: any[] = [
    {
      text: "Company Name",
      value: "name",
    },
  ];
  items: Models.HoldingCompany[] = [];
  getHoldingCompaniesButtonState: ButtonState = "ready";

  created() {
    this.$store.commit("updateField", { path: "holdingCompanies", value: [] });
    this.getData();
  }

  edit() {
    let holdingCompany = this.selected.length > 0 ? this.selected[0] : new Models.HoldingCompany();
    this.$store.commit("updateField", { path: "holdingCompany", value: holdingCompany });
    this.$router.push({ name: "holding-company" });
  }

  async getData() {
    try {
      this.getHoldingCompaniesButtonState = "busy";
      let holdingCompanies = await getHoldingCompanies();
      console.log(JSON.stringify(holdingCompanies));
      this.$store.commit("updateField", { path: "holdingCompanies", value: holdingCompanies });
      this.$forceUpdate();
      this.getHoldingCompaniesButtonState = "success";
    } catch (err) {
      this.getHoldingCompaniesButtonState = "error";
      console.log("getData() error: " + err);
      throw err;
    }
  }
}
