/* eslint-disable no-console */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import firebase from "firebase/app";
import "firebase/auth";

Vue.config.productionTip = false;

let firebaseConfig;
if (store.state.environment === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyAnm2wf7gkEjHhNi59Fv2-U8-isAZhD6UA",
    authDomain: "gigalot-cloud.firebaseapp.com",
    databaseURL: "https://gigalot-cloud.firebaseio.com",
    projectId: "gigalot-cloud",
    storageBucket: "gigalot-cloud.appspot.com",
    messagingSenderId: "820134020112",
    appId: "1:820134020112:web:aca333d7b679d9670e3695",
    measurementId: "G-TXJ8L5H9HM"
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyCxvvbSahUACHC8z76pXKVQex9XCGB5iY4",
    authDomain: "gigalot-testing.firebaseapp.com",
    databaseURL: "https://gigalot-testing.firebaseio.com",
    projectId: "gigalot-testing",
    storageBucket: "gigalot-testing.appspot.com",
    messagingSenderId: "17723559284",
    appId: "1:17723559284:web:1e7ff0a2a958ac5f137021",
    measurementId: "G-ZDK57XDFC8"
  };
}

firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged(function (user) {
  store.dispatch("user/firebaseOnAuthStateChanged", user);
});

store.commit("version");

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
