
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import store, { mapFields, mapMultiRowFields } from "@/store";
import { updateHoldingCompany } from "@/helpers/graphql";

@Component({
  components: {
    DimssaButton,
  },
  computed: {
    ...mapFields(["holdingCompany.name", "holdingCompany.gcp", "holdingCompany.feedlots"]),
    ...mapFields({ gpsLat: "holdingCompany.gps.lat", gpsLon: "holdingCompany.gps.lon" }),
  },
})
export default class HoldingCompany extends Vue {
  saveButtonState: ButtonState = "ready";

  get holdingCompany(): Models.HoldingCompany {
    return this.$store.getters["getField"]("holdingCompany") as Models.HoldingCompany;
  }

  created() {
    if (this.holdingCompany && !this.holdingCompany.gps) this.holdingCompany.gps = { lat: undefined, lon: undefined };
    //console.log(JSON.stringify(this.holdingCompany));
  }

  feedlotHeaders: any[] = [
    {
      text: "Name",
      value: "name",
    },
  ];
  selectedFeedlot: Models.Feedlot[] = [];
  searchFeedlot: string = "";

  editFeedlot() {
    let feedlot = this.selectedFeedlot.length > 0 ? this.selectedFeedlot[0] : new Models.Feedlot();
    this.$store.commit("updateField", { path: "feedlot", value: feedlot });
    this.$router.push({ name: "feedlot" });
  }

  abattoirHeaders: any[] = [
    {
      text: "Name",
      value: "name",
    },
  ];
  selectedAbattoir: Models.Feedlot[] = [];
  searchAbattoir: string = "";

  editAbattoir() {
    let abattoir = this.selectedAbattoir.length > 0 ? this.selectedAbattoir[0] : new Models.Abattoir();
    this.$store.commit("updateField", { path: "abattoir", value: abattoir });
    this.$router.push({ name: "abattoir" });
  }

  async save() {
    try {
      this.saveButtonState = "busy";
      updateHoldingCompany(this.holdingCompany);
      this.saveButtonState = "success";
    } catch (err) {
      console.error(`Error saving Holding Company`);
      console.error(err);
      this.saveButtonState = "error";
    }
  }
}
