
import { Component, Prop, Vue } from "vue-property-decorator";

type ButtonState = "ready" | "busy" | "success" | "error" | "disabled";

@Component
export default class DimssaButton extends Vue {
  @Prop() buttonState!: ButtonState;

  @Prop() buttonClass!: string;

  @Prop() tabindex?: number;

  name: String = "DimssaButton";

  get buttonColour(): String {
    switch (this.buttonState) {
      case "ready":
        return "primary";
      case "busy":
        return "secondary"; //light gray, black text
      case "success":
        return "success";
      case "error":
        return "error";
      case "disabled":
        return "secondary";
      default:
        return "";
    }
  }

  onClickButton() {
    this.$emit("onclick");
  }

  onClickDiv() {
    if (this.buttonState === "disabled") {
      this.$emit("onclick-disabled");
    }
  }
}

export { DimssaButton, ButtonState };
