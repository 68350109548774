
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import store, { mapFields, mapMultiRowFields } from "@/store";
import lodash from "lodash";
import * as helpers from "@/helpers";
import { downloadObjectAsJson, downloadObjectAsText } from "@/helpers/download";
import { camelCase, getProjectId } from "@/helpers";
import { mqttDeviceId, mqttDeviceRegistryId, mqttPrivateKey, mqttPushCertificate, mqttRegisterDevice } from "@/helpers/mqtt";
import { getGcp } from "@gigalot/utils";
import { updateHoldingCompany } from "@/helpers/graphql";
import JSZip from "jszip";
import { createServiceAccount } from "@/helpers/index";
import { eXgetGcp } from "@/helpers/index";
import { MqttDeviceStatus } from "@/components/MqttDeviceStatus.vue";
import { configFileProxyGantryScanner } from "@/helpers/config-generator";

@Component({
  components: {
    DimssaButton,
    MqttDeviceStatus,
  },
  computed: {
    ...mapFields(["gantryScanner.description"]),
  },
})
export default class GantryScanner extends Vue {
  saveButtonState: ButtonState = "ready";

  mqttDevice = {};

  created() {
    this.mqttDevice = {
      id: this.mqttDeviceId(),
      registry: "Gantry_Scanners",
      state: undefined,
    };
  }

  mqttDeviceId() {
    const what: string = "gantry-scanner";
    const feedlot: Models.Feedlot = this.$store.getters["getField"]("feedlot") as Models.Feedlot;
    let gantryScanners: Models.GantryScanner[] = feedlot.gantryScanners;
    let index = gantryScanners.findIndex((gs) => gs.guid === this.gantryScanner.guid);
    let num = index + 1;
    if (index < 0) {
      //user might be busy with a new gantry scanner that has not been saved yet.
      num = gantryScanners.length + 1;
    }
    return mqttDeviceId(what, this.getGcp(), this.getGuid(), num);
  }

  get gantryScanner(): Models.GantryScanner {
    return this.$store.getters["getField"]("gantryScanner");
  }

  async save() {
    try {
      this.saveButtonState = "busy";
      const holdingCompany: Models.HoldingCompany = this.$store.getters["getField"]("holdingCompany") as Models.HoldingCompany;
      const feedlot: Models.Feedlot = this.$store.getters["getField"]("feedlot") as Models.Feedlot;
      if (!feedlot.gantryScanners) feedlot.gantryScanners = [];
      helpers.addToListGuids(feedlot.gantryScanners, this.gantryScanner);
      helpers.addToListGuids(holdingCompany.feedlots, feedlot);
      this.$store.commit("updateField", { path: "feedlot", value: feedlot });
      await updateHoldingCompany(this.$store.getters["getField"]("holdingCompany"));
      this.saveButtonState = "success";
    } catch (err) {
      console.error(`Error saving Gantry Scanner`);
      console.error(err);
      this.saveButtonState = "error";
    }
  }

  configFile(download = true) {
    let json = configFileProxyGantryScanner({ mqttDeviceId: this.mqttDeviceId() });
    if (download) downloadObjectAsJson(json, "config");
    return json;
  }

  getGuid() {
    const feedlot: Models.Feedlot = this.$store.getters["getField"]("feedlot") as Models.Feedlot;
    return feedlot.guid;
  }

  getGcp() {
    const feedlot: Models.Feedlot = this.$store.getters["getField"]("feedlot") as Models.Feedlot;
    let holdingCompany = this.$store.getters["getField"]("holdingCompany");
    return getGcp(feedlot, holdingCompany);
  }

  serviceAccountName() {
    return `${eXgetGcp().slice(0, 29)}`;
  }

  async createServiceAccount(download = true) {
    try {
      let data = await createServiceAccount(this.serviceAccountName(), download);
      return data;
    } catch (err) {
      console.log("error: " + err);
      throw err;
    }
  }

  async downloadArchive() {
    var zip = new JSZip();

    zip.file("service-account.json", await this.createServiceAccount(false));
    //zip.file("mqtt.pem", await this.mqttPrivateKey(false));
    zip.file("config.json", JSON.stringify(this.configFile(false)));

    zip.generateAsync({ type: "blob" }).then(function (content) {
      var downloadAnchorNode = document.createElement("a");

      let dataURL = (window.URL || window.webkitURL).createObjectURL(content);
      downloadAnchorNode.setAttribute("href", dataURL);
      downloadAnchorNode.setAttribute("download", `archive.zip`);
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  }
}
