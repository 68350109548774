
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import store, { mapFields, mapMultiRowFields } from "@/store";
import * as helpers from "@/helpers";
import { downloadObjectAsJson, downloadObjectAsText } from "@/helpers/download";
import { camelCase, getProjectId } from "@/helpers";
import { mqttDeviceId, mqttDeviceRegistryId, mqttPrivateKey, mqttRegisterDevice, mqttPushCertificate } from "@/helpers/mqtt";
import { createDatasetTable, updateHoldingCompany } from "@/helpers/graphql";
import { createServiceAccount } from "@/helpers/index";
import JSZip from "jszip";
import { eXgetGcp } from "@/helpers/index";
import { getGcp } from "@gigalot/utils";
import { MqttDeviceStatus } from "@/components/MqttDeviceStatus.vue";
import { configFileOffice, configFileFeeder, configFileMixing, configFileWeather } from "@/helpers/config-generator";

@Component({
  components: {
    DimssaButton,
    MqttDeviceStatus,
  },
  computed: {
    ...mapFields(["feedlot.name" /*"feedlot.gcp"*/, , "feedlot.vat"]),
  },
})
export default class Feedlot extends Vue {
  selectedFeedlotDestination: Models.FeedlotDestination[] = [];
  searchFeedlotDestination: string = "";
  selectedProcessingStation: Models.ProcessingStation[] = [];
  searchProcessingStation: string = "";
  selectedGantryScanner: Models.GantryScanner[] = [];
  searchGantryScanner: string = "";
  selectedHandHeldScanner: Models.HandHeldScanner[] = [];
  searchHandHeldScanner: string = "";
  serviceAccountName: string = "";
  selectedFeedingServer: any[] = [];
  searchFeedingServer: string = "";
  selectedMixingServer: any[] = [];
  searchMixingServer: string = "";

  gcp: string = "";

  topics: string[] = [];

  pubsubGetTopicsButtonState: ButtonState = "ready";
  pubsubCreateTopicsButtonState: ButtonState = "ready";

  createBigQueryTablesButtonState: ButtonState = "ready";

  createServiceAccountButtonState: ButtonState = "ready";

  saveButtonState: ButtonState = "ready";

  mqttDevices: { [name: string]: { id: string; registry: string; state?: any } } = {};

  async createDatasetTable(datasetId: string) {
    try {
      this.createBigQueryTablesButtonState = "busy";
      await createDatasetTable(datasetId, "feedlot");
      this.createBigQueryTablesButtonState = "success";
    } catch (err) {
      console.error(err);
      this.createBigQueryTablesButtonState = "error";
    }
  }

  get feedlot(): Models.Feedlot {
    return this.$store.getters["getField"]("feedlot");
  }

  created() {
    if (!this.feedlot.gantryScanners) {
      this.feedlot.gantryScanners = [];
      this.$store.commit("updateField", { path: "feedlot", value: this.feedlot });
    }

    this.gcp = this.getGcp();

    for (const s of ["office-server", "feeder-server", "mixing-server", "weather-station"]) {
      this.mqttDevices[s] = { id: this.mqttDeviceId(s), registry: mqttDeviceRegistryId(s) };
    }
  }

  mqttDeviceId(what: string) {
    let num;
    if (what === "office-server") num = 1;
    if (what === "mixing-server") num = 1;
    if (what === "feeder-server") num = 1;
    if (what === "weather-station") num = 1;
    return mqttDeviceId(what, eXgetGcp(), this.getGuid(), num);
  }

  mounted() {
    this.$store.dispatch("user/addFirebaseCallback", this.loadDefaults);
  }

  loadDefaults() {
    this.serviceAccountName = `${eXgetGcp().slice(0, 29)}`;
    console.log(`service account name: ${this.serviceAccountName}`);
  }

  editFeedlotDestination() {
    let feedlotDestination = this.selectedFeedlotDestination.length > 0 ? this.selectedFeedlotDestination[0] : new Models.FeedlotDestination();
    this.$store.commit("updateField", { path: "feedlotDestination", value: feedlotDestination });
    this.$router.push({ name: "feedlot-destination" });
  }

  editProcessingStation() {
    let processingStation = this.selectedProcessingStation.length > 0 ? this.selectedProcessingStation[0] : new Models.ProcessingStation();
    this.$store.commit("updateField", { path: "processingStation", value: processingStation });
    this.$router.push({ name: "processing-station" });
  }

  editGantryScanner() {
    if (!this.feedlot.gantryScanners) this.feedlot.gantryScanners = [];
    let gantryScanner = this.selectedGantryScanner.length > 0 ? this.selectedGantryScanner[0] : new Models.GantryScanner();
    this.$store.commit("updateField", { path: "gantryScanner", value: gantryScanner });
    this.$router.push({ name: "gantry-scanner" });
  }

  editHandHeldScanner() {
    if (!this.feedlot.handHeldScanners) this.feedlot.handHeldScanners = [];
    const handHeldScanner = this.selectedHandHeldScanner.length > 0 ? this.selectedHandHeldScanner[0] : new Models.HandHeldScanner();
    this.$store.commit("updateField", { path: "handHeldScanner", value: handHeldScanner });
    this.$router.push({ name: "hand-held-scanner" });
  }

  async save() {
    try {
      this.saveButtonState = "busy";
      const holdingCompany: Models.HoldingCompany = this.$store.getters["getField"]("holdingCompany") as Models.HoldingCompany;
      const feedlot: Models.Feedlot = this.$store.getters["getField"]("feedlot") as Models.Feedlot;
      helpers.addToListGuids(holdingCompany.feedlots, feedlot);
      await updateHoldingCompany(holdingCompany);
      this.saveButtonState = "success";
    } catch (err) {
      console.error(`Error saving Feedlot`);
      console.error(err);
      this.saveButtonState = "error";
    }
  }

  getGcp() {
    const holdingCompany = this.$store.getters["getField"]("holdingCompany");
    const feedlot = store.getters["getField"]("feedlot");
    return getGcp(feedlot, holdingCompany);
  }

  getGuid() {
    return this.$store.getters["getField"]("feedlot").guid;
  }

  get datasetId() {
    const prefix = eXgetGcp();
    let guid = this.$store.getters["getField"]("feedlot").guid;
    guid = guid.split("-").join(""); //remove all dashes
    const datasetId = `${prefix}_${guid}`;
    console.log(datasetId);
    return datasetId;
  }

  async getPubSubTopics() {
    try {
      this.pubsubGetTopicsButtonState = "busy";
      let gql = `query pubSubTopics {
        pubSubTopics(gcp: "${eXgetGcp()}", locationGuid: "${this.$store.getters["getField"]("feedlot").guid}")
      }`;

      let json = await this.$store.dispatch("graphQl", { gql });

      console.log(JSON.stringify(json));
      this.pubsubGetTopicsButtonState = "success";
    } catch (err) {
      console.log("error: " + err);
      this.pubsubGetTopicsButtonState = "error";
      throw err;
    }
  }

  async createPubSubTopics() {
    try {
      this.pubsubCreateTopicsButtonState = "busy";
      let gql = `mutation createPubSubTopics($gcp: String!, $locationGuid: String!) {
        createPubSubTopics(gcp: $gcp, locationGuid: $locationGuid)
      }`;

      let json = await this.$store.dispatch("graphQl", {
        gql,
        variables: { gcp: eXgetGcp(), locationGuid: this.$store.getters["getField"]("feedlot").guid },
      });

      console.log(JSON.stringify(json));
      this.pubsubCreateTopicsButtonState = "success";
    } catch (err) {
      console.log("error: " + err);
      this.pubsubCreateTopicsButtonState = "error";
      this.$store.commit("popup/displayOk", `Error: ${err}`);
    }
  }

  async createServiceAccount(download = true) {
    try {
      this.createServiceAccountButtonState = "busy";
      let data = await createServiceAccount(this.serviceAccountName, download);
      if (data != null) this.createServiceAccountButtonState = "success";
      else this.createServiceAccountButtonState = "error";
      return data;
    } catch (err) {
      console.log("error: " + err);
      this.createServiceAccountButtonState = "error";
      throw err;
    }
  }

  configFileFeeder(download = true) {
    const holdingCompany = this.$store.getters["getField"]("holdingCompany");
    const feedlot = this.$store.getters["getField"]("feedlot");

    const filename = `config_Feeder-${camelCase(feedlot.name)}_${holdingCompany.typename}-${camelCase(holdingCompany.name)}`;

    const json = configFileFeeder({ feedlot, holdingCompany });

    if (download) downloadObjectAsJson(json, filename);
    return json;
  }

  configFileMixing() {
    const holdingCompany = this.$store.getters["getField"]("holdingCompany");
    const feedlot = this.$store.getters["getField"]("feedlot");

    const filename = `config_Mixing-${camelCase(feedlot.name)}_${holdingCompany.typename}-${camelCase(holdingCompany.name)}`;

    const json = configFileMixing({ feedlot, holdingCompany });

    downloadObjectAsJson(json, filename);
  }

  configFileOffice(download = true) {
    const holdingCompany = this.$store.getters["getField"]("holdingCompany");
    const feedlot = this.$store.getters["getField"]("feedlot");

    const filename = `config_Feedlot-${camelCase(feedlot.name)}_${holdingCompany.typename}-${camelCase(holdingCompany.name)}`;

    const json = configFileOffice({ feedlot, holdingCompany });

    if (download) downloadObjectAsJson(json, filename);
    return json;
  }

  configFileWeather(download: boolean) {
    const holdingCompany = this.$store.getters["getField"]("holdingCompany");
    const feedlot = this.$store.getters["getField"]("feedlot");

    const filename = `config_WeatherStation-${camelCase(feedlot.name)}_${holdingCompany.typename}-${camelCase(holdingCompany.name)}`;

    const json = configFileWeather({ feedlot, holdingCompany });

    if (download) downloadObjectAsJson(json, filename);
    return json;
  }

  async downloadArchive(server: string) {
    var zip = new JSZip();

    switch (server) {
      case "feeder-server":
        zip.file("config.json", JSON.stringify(this.configFileFeeder(false)));
        break;
      case "office-server":
        zip.file("config.json", JSON.stringify(this.configFileOffice(false)));
        break;
      case "weather-station":
        zip.file("config.json", JSON.stringify(this.configFileWeather(false)));
        break;
      default:
        break;
    }
    zip.file("service-account.json", await this.createServiceAccount(false));
    //zip.file("mqtt.pem", await this.mqttPrivateKey(server, false));

    zip.generateAsync({ type: "blob" }).then(function (content) {
      var downloadAnchorNode = document.createElement("a");

      let dataURL = (window.URL || window.webkitURL).createObjectURL(content);
      downloadAnchorNode.setAttribute("href", dataURL);
      downloadAnchorNode.setAttribute("download", `archive.zip`);
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  }

  userSearch: string = "";
  users: any[] = [];
  getUsersButtonState: ButtonState = "ready";

  async getUsers() {
    this.getUsersButtonState = "busy";
    try {
      let gql = `query users($locationGuid: String) {
        users(locationGuid: $locationGuid) {
          uid
          displayName
          email
          apps {
            role
            guid
          }
        }
      }`;

      let json = await this.$store.dispatch("graphQl", { gql, variables: { locationGuid: this.feedlot.guid } });
      console.log(JSON.stringify(json.data));
      this.users = json.data.users.flatMap((u: any) =>
        u.apps.map((a: any) => ({ displayName: u.displayName, email: u.email, role: a.role, key: u.email + a.role }))
      );
      this.getUsersButtonState = "success";
    } catch (err) {
      this.getUsersButtonState = "error";
      console.log("error: " + err);
    }
  }
}
