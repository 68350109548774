
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import lodash from "lodash";

@Component({
  components: {
    DimssaButton,
  },
  computed: {},
})
export default class Role extends Vue {
  roleTypes: any[] = ["office_administrator", "processing_supervisor", "field_app_user", "feeder_supervisor"];
  roleType?: any = "";

  selectableAccessRights: string[] = [];
  accessRights: string[] = [];

  saveButtonState: ButtonState = "ready";

  @Watch("roleType")
  onRoleTypeChange(roleType: string) {
    const role = this.$store.getters["getField"]("userRole");
    if (!role) return;
    if (role.role !== roleType) {
      this.accessRights = [];
    }
    switch (roleType) {
      case "office_administrator":
        this.selectableAccessRights = [];
        return;
      case "processing_supervisor":
        this.selectableAccessRights = [];
        return;
      case "field_app_user":
        this.selectableAccessRights = ["mortality"];
        return;
      case "feeder_supervisor":
        this.selectableAccessRights = [];
        return;
    }
  }

  get user() {
    return this.$store.getters["getField"]("user");
  }

  get holdingCompanies(): Models.HoldingCompany[] {
    return this.$store.getters["getField"]("holdingCompanies");
  }
  holdingCompany: any = "";

  get sites() {
    let feedlots = this.holdingCompany.feedlots;
    let abattoirs = this.holdingCompany.abattoirs;
    let companies = lodash.union(feedlots, abattoirs);
    return companies;
  }
  site?: any = "";

  mounted() {
    const role = this.$store.getters["getField"]("userRole");
    if (role) {
      this.roleType = role.role;
      //find holdingCompany which contains site guid
      this.holdingCompany = this.holdingCompanies.find((hc) => {
        let companies = lodash.union(hc.feedlots, hc.abattoirs);
        return companies.find((c) => c.guid === role.guid) !== undefined;
      });

      const hc = this.holdingCompany;
      let companies = lodash.union(hc.feedlots, hc.abattoirs);

      this.site = this.sites.find((s: any) => s.guid === role.guid);

      this.accessRights = role.accessRights;
    }
    const b = true;
  }

  async save() {
    if (!this.roleType) {
      console.log("no role type selected");
      return;
    }
    if (!this.site) {
      console.log("no site selected");
      return;
    }

    let gql = `mutation updateUserRoles($userApp: UserAppInput!) {
      updateUserRoles(userApp: $userApp)
    }`;

    try {
      let json = await this.$store.dispatch("graphQl", {
        gql,
        variables: {
          userApp: {
            uid: this.user.uid,
            role: this.roleType,
            guid: this.site.guid,
            accessRights: this.accessRights,
          },
        },
      });
      console.log(JSON.stringify(json));
    } catch (err) {
      console.log(err);
    }
  }
}
