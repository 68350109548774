
import { Component, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/dimssa-button.vue";

@Component({
  components: {
    DimssaButton,
    Keypress: () => import("vue-keypress"),
  },
})
export default class Login extends Vue {
  email: string = "";
  password: string = "";
  errorMessage: string = "";
  signInButtonState: ButtonState = "ready";

  async signIn() {
    try {
      this.errorMessage = "";
      this.signInButtonState = "busy";
      console.log(this.email);
      await this.$store.dispatch("user/signInWithEmailPassword", {
        email: this.email.trim(),
        password: this.password,
      });
    } catch (err) {
      this.signInButtonState = "error";
      console.log("signInWithEmailPassword error: " + err);
      this.errorMessage = err as string;
    }
  }
}
