
import { Component, Vue, Watch, Prop, Model } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/dimssa-button.vue";
import * as Models from "@gigalot/data-models";

@Component({
  components: {
    DimssaButton,
  },
})
export default class DestinationAbattoirs extends Vue {
  search: string = "";
  selected: any[] = [];
  headers: any[] = [
    {
      text: "Name",
      value: "name",
    },
  ];
  items: Models.Abattoir[] = [];
  getDestinationAbattoirsButtonState: ButtonState = "ready";

  created() {
    this.$store.commit("updateField", { path: "destinationAbattoirs", value: [] });
    this.getData();
  }

  edit() {
    let destinationAbattoir = this.selected.length > 0 ? this.selected[0] : new Models.Abattoir();
    this.$store.commit("updateField", { path: "destinationAbattoir", value: destinationAbattoir });
    this.$router.push({ name: "destination-abattoir" });
  }

  async getData() {
    try {
      this.getDestinationAbattoirsButtonState = "busy";
      let gql = `{
        destinationAbattoirs {
          ...companyFields
        }
      }
      fragment companyFields on ICompany {
        typename
        guid
        gcp
        name
        alias
        vat
        gps {
          lat
          lon
        }
      }`;

      let json = await this.$store.dispatch("graphQl", { gql });
      console.log(JSON.stringify(json));
      this.$store.commit("updateField", { path: "destinationAbattoirs", value: json.data.destinationAbattoirs });
      this.$forceUpdate();
      this.getDestinationAbattoirsButtonState = "success";
    } catch (err) {
      this.getDestinationAbattoirsButtonState = "error";
      console.log("getData() error: " + err);
      throw err;
    }
  }
}
