import { default as Vuex, Module } from "vuex";
import router from "@/router";

import firebase from "firebase/app";
import "firebase/auth";

/*
The idea behind hasfirebaseOnAuthStateChangedFired and callbacks:

The Vue components's lifecycle hook "mounted()" fires before firebase.auth().onAuthStateChanged fires
So that means mounted() can not have code that accesses firebase, which is limiting and annoying

To fix this we can use addCallback(func) that will call func after firebase has completed onAuthStateChanged
*/
let hasfirebaseOnAuthStateChangedFired: boolean = false;
let callbacks: (() => void)[] = [];

function addCallback(callback: () => void) {
  if (hasfirebaseOnAuthStateChangedFired) callback();
  else callbacks.push(callback);
}

function fireCallbacks() {
  hasfirebaseOnAuthStateChangedFired = true;
  let callback: (() => void) | undefined;
  while ((callback = callbacks.shift())) callback();
}

class UserState {
  /*
  some of the fields of firebase.User (user):

  displayName: string | null;
  email: string | null;
  phoneNumber: string | null;
  photoURL: string | null;
  providerId: string;
  uid: string;
  */
  //user?: firebase.User;
  user?: any;
  authorizationLevel?: number;
  gcp?: string;
  location?: string;
  accessToken?: string;
}

class UserModule implements Module<UserState, any> {
  namespaced = true;
  state: UserState = new UserState();
  mutations = {
    setAuthorizationLevel(state: UserState, payload: number) {
      state.authorizationLevel = payload;
    },
    setUser(state: UserState, payload: any) {
      state.user = payload;
      if (!payload) {
        state.authorizationLevel = undefined;
        state.gcp = undefined;
        state.location = undefined;
      }
    },
    setUserInfo(state: UserState, payload: { authorizationLevel: number; gcp: string; location: string }) {
      state.authorizationLevel = payload.authorizationLevel;
      state.gcp = payload.gcp;
      state.location = payload.location;
    },
    updateAccessToken(state: UserState, payload: { jwt: string }) {
      state.accessToken = payload.jwt;
    }
  };
  actions = {
    async getOfflineIdToken({ state, commit, rootState }: { state: UserState; commit: any; rootState: any }) {
      return state.accessToken;
    },
    async getOnlineIdToken({ state, commit, rootState }: { state: UserState; commit: any; rootState: any }) {
      let user = firebase.auth().currentUser;
      let jwt;
      if (!user) {
        throw Error("No user");
      }
      jwt = await user.getIdToken();
      //console.log(jwt);
      if (!jwt) {
        throw Error("No jwt");
      }
      commit("updateAccessToken", { jwt: jwt });
      return jwt;
    },
    async signInWithEmailPassword({ state, commit, rootState }: { state: UserState; commit: any; rootState: any }, obj: { email: string; password: string }) {
      // console.log(obj.email);
      // console.log(obj.password);
      let result = await firebase.auth().signInWithEmailAndPassword(obj.email, obj.password); //try catch happening outside method
      let user = result.user;
      if (!user) {
        throw Error("signInWithEmailAndPassword no result.user");
      }
      console.log(JSON.stringify(user));

      if (user.email !== "dolan@gigalot.co.za" && user.email !== "paul@gigalot.co.za" && user.email !== "william@gigalot.co.za") {
        throw Error("Unauthorized access.");
      }

      let token;
      try {
        //let jwt = await user.getIdToken();
        token = await user.getIdTokenResult();
        //console.log(token.claims);
      } catch (error) {
        throw Error("Error getting user token.");
      }

      if (!token) {
        throw Error("Id token not found.");
      }

      let jwt = await user.getIdToken();
      //console.log("signInWithEmailPassword access token:\n" + jwt)

      commit("setUser", user);
      commit("updateAccessToken", { jwt: jwt });
      commit("setUserInfo", {
        authorizationLevel: token.claims.authorizationLevel,
        gcp: token.claims.gcp,
        location: token.claims.location
      });
      console.log(`signInWithEmailPassword router.push({ path: "/" });`);
      router.push({ path: "/" });
    },
    signOut({ state, commit, rootState }: { state: UserState; commit: any; rootState: any }) {
      firebase
        .auth()
        .signOut()
        .then(function() {
          commit("setUser", undefined);
          router.push({ path: "/login" });
          console.log("user signed out");
        })
        .catch(function(error) {
          console.log("error signing out");
        });
    },
    firebaseOnAuthStateChanged({ state, commit, rootState, dispatch }: { state: UserState; commit: any; rootState: any; dispatch: any }, user: firebase.User) {
      console.log("user vuex sub module firebaseOnAuthStateChanged");
      //commit("setUser", user);

      /*
        Remember that this method gets called shortly after every reload.
        If there is a user then do nothing since they're already logged in.
        If there is no user then log out. This shouldn't really be needed, but it's here for just in case.
      */
      if (!user) {
        console.log("no user");
        commit("setUser", undefined);
        commit("updateAccessToken", { jwt: undefined });
        if (router.currentRoute.path !== "/login") router.push({ path: "/login" });
      }

      // console.log("user: " + user);
      // console.log("firebase.auth().currentUser: " + firebase.auth().currentUser);
      fireCallbacks();
    },
    addFirebaseCallback({ state, commit, rootState, dispatch }: { state: UserState; commit: any; rootState: any; dispatch: any }, callback: () => void) {
      addCallback(callback);
    }
  };
  getters = {
    isUserRegistered(state: UserState, getters: any, rootState: any, rootGetters: any) {
      /*
        Returning a function, because for some reason just returning the boolean didn't work.
        calling `if (store.getters["login/isUserRegistered"])` still gets called if isUserRegistered is spelled wrong.
        rather use `if (store.getters["login/isUserRegistered"]())` because then if isUserRegistered is spelled wrong we'll get an error saying so.
      */
      return () => {
        let isUserRegistered: boolean = state.user ? true : false;
        return isUserRegistered;
      };
    }
  };
}

const userModule: UserModule = new UserModule();

export default userModule;
