import store from "@/store";
import firebase from "firebase/app";
import "firebase/firestore";

export function mqttDeviceId(what: string, gcp: string, guid: string, num: number = 1) {
  const prefix = `${gcp}_${guid}`;
  if (what === "office-server") return `${prefix}.OfficeServer.${num}`;
  if (what === "mixing-server") return `${prefix}.MixingServer.${num}`;
  if (what === "feeder-server") return `${prefix}.FeederServer.${num}`;
  if (what === "weather-station") return `${prefix}.WeatherStation.${num}`;
  if (what === "processing-station") return `${prefix}.ProcessingStation.${num}`;
  if (what === "gantry-scanner") return `${prefix}.GantryScanner.${num}`;
  if (what === "hand-held-scanner") return `${prefix}.HandHeldScanner.${num}`;
  if (what === "abattoir") return `${prefix}.Abattoir.${num}`;
  throw Error("mqttDeviceId(): unknown 'what'");
}

export function mqttDeviceRegistryId(what: string) {
  if (what === "office-server") return "Office_Servers";
  if (what === "mixing-server") return "Mixing_Servers";
  if (what === "feeder-server") return "Feeder_Servers";
  if (what === "processing-station") return "Processing_Stations";
  if (what === "weather-station") return "Weather_Stations";
  if (what === "gantry-scanner") return "Gantry_Scanners";
  if (what === "hand-held-scanner") return "Handheld_Scanners";
  if (what === "abattoir") return "Abattoirs";
  throw Error("mqttDeviceRegistryId(): unknown 'what'");
}

export async function mqttPrivateKey(deviceId: string) {
  try {
    let gql = `query mqttPrivateKey($deviceId: String!) {
			mqttPrivateKey(deviceId: $deviceId)
		}`;

    //const deviceId = mqttDeviceId(what, gcp, guid, num);
    let json = await store.dispatch("graphQl", {
      gql,
      variables: { deviceId }
    });

    console.log(JSON.stringify(json));
    return json;
  } catch (err) {
    console.log("error: " + err);
    throw err;
  }
}

export async function mqttRegisterDevice(deviceId: string, registryId: string) {
  console.warn("mqttRegisterDevice doing nothing.");
  // try {
  //   let gql = `mutation mqttRegisterDevice($deviceId: String!, $registryId: String!) {
  // 		mqttRegisterDevice(deviceId: $deviceId, registryId: $registryId)
  // 	}`;

  //   let json = await store.dispatch("graphQl", {
  //     gql,
  //     //variables: { deviceId: mqttDeviceId(what, gcp, guid, num), registryId: mqttDeviceRegistryId(what) }
  //     variables: { deviceId, registryId }
  //   });

  //   console.log(JSON.stringify(json));
  //   return json;
  // } catch (err) {
  //   console.log("error: " + err);
  //   throw err;
  // }
}

export async function mqttUpdateDevice(deviceId: string, registryId: string, arch: string = "") {
  try {
    let gql = `mutation mqttUpdateDevice($deviceId: String!, $registryId: String!, $arch: String!) {
    mqttUpdateDevice(deviceId: $deviceId, registryId: $registryId, arch: $arch)
  }`;

    let json = await store.dispatch("graphQl", {
      gql,
      variables: { deviceId, registryId, arch },
    });

    console.log(JSON.stringify(json));
    return json;
  } catch (err) {
    console.log("error: " + err);
    throw err;
  }
}

export async function mqttResetDevice(deviceId: string, registryId: string) {
  try {
    let gql = `mutation mqttResetDevice($deviceId: String!, $registryId: String!) {
      mqttResetDevice(deviceId: $deviceId, registryId: $registryId)
  }`;

    let json = await store.dispatch("graphQl", {
      gql,
      variables: { deviceId, registryId }
    });

    console.log(JSON.stringify(json));
    return json;
  } catch (err) {
    console.log("error: " + err);
    throw err;
  }
}

export async function mqttUpdateDeviceConfig(deviceId: string, registryId: string, version: string, data: string) {
  try {
    const gql = `mutation mqttUpdateDeviceConfig($deviceId: String!, $registryId: String!, $data: String!, $version: String!) {
      mqttUpdateDeviceConfig(deviceId: $deviceId, registryId: $registryId, version: $version, data: $data)
    }`;

    const json = await store.dispatch("graphQl", {
      gql,
      variables: { deviceId, registryId, version, data },
    });

    console.log(JSON.stringify(json));
    return json;
  } catch (err) {
    console.log("error: " + err);
    throw err;
  }
}

export async function mqttPushCertificate(deviceId: string, registryId: string) {
  try {
    const gql = `mutation mqttPushCertificate($deviceId: String!, $registryId: String!) {
			mqttPushCertificate(deviceId: $deviceId, registryId: $registryId)
		}`;

    const json = await store.dispatch("graphQl", {
      gql,
      variables: { deviceId, registryId },
    });

    console.log(JSON.stringify(json));
    return json;
  } catch (err) {
    console.log("error: " + err);
    throw err;
  }
}

export async function mqttGetDeviceConfig(item: any) {
  const gql = `query getMqttDeviceConfig($deviceId: String!,$registryId: String!){
      getMqttDeviceConfig(deviceId: $deviceId, registryId: $registryId)
    }`;
  const response = await store.dispatch("graphQl", { gql, variables: { deviceId: item.id, registryId: item.registry } });
  return response?.data?.getMqttDeviceConfig;
}

/*
  o.state will be updated
  returns function called to unsubscribe
*/
export function mqttStreamDeviceState(o: { id?: string; registry?: string; }, cb: (timeStateUpdated?: number, state?: any) => void) {
  if (!o.id) throw Error("getMqttDeviceState no item.id");
  if (!o.registry) throw Error("getMqttDeviceState no item.registry");
  console.log(`Streaming MQTT device state for ${o.id}`);
  if (!firebase.apps.length) throw Error("no firebase app!");
  const firestore = firebase.firestore();
  const unsubscribeFunc = firestore
    .collection("Mqtt")
    .doc(o.id)
    .onSnapshot({
      next: (snapshot) => {
        console.log(`MQTT device data onSnapshot for ${o.id}`);
        const data = snapshot.data();
        const state = data?.state ? JSON.parse(data?.state) : undefined;
        const timeStateUpdated = data?.timeStateUpdated;
        cb(timeStateUpdated, state);
      },
      error: (error) => {
        console.error(`MQTT device state firestore onSnapshot error:`);
        console.error(JSON.stringify(error));
      },
    });
  return unsubscribeFunc;
}
