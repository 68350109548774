import store from "@/store";

export async function iotUpdateDevice(deviceId: string, registryId: string, arch: string = "") {
  try {
    let gql = `mutation iotUpdateDevice($deviceId: String!, $registryId: String!, $arch: String!) {
    iotUpdateDevice(deviceId: $deviceId, registryId: $registryId, arch: $arch)
  }`;

    let json = await store.dispatch("graphQl", {
      gql,
      variables: { deviceId, registryId, arch },
    });

    console.log(JSON.stringify(json));
    return json;
  } catch (err) {
    console.log("error: " + err);
    throw err;
  }
}